import { CsvTemplateBuilder } from 'commons/utils/CSVTemplateBuilder';

const PharmacyCopyInvoiceFlagBulkUploadTemplate = new CsvTemplateBuilder(
  'pharmacy-copy-invoice-flag-bulk-upload',
);
PharmacyCopyInvoiceFlagBulkUploadTemplate.setHeader([
  'pharmacy_id',
  'is_copy_invoice',
]);

PharmacyCopyInvoiceFlagBulkUploadTemplate.addRowValues(['10', '0']);
PharmacyCopyInvoiceFlagBulkUploadTemplate.addRowValues(['11', '1']);
PharmacyCopyInvoiceFlagBulkUploadTemplate.addRowValues(['12', '1']);

export { PharmacyCopyInvoiceFlagBulkUploadTemplate };
