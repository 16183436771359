import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Business,
  Dashboard,
  Inventory,
  MedicalServices,
  MoveDown,
  OpenInFull,
  Person,
  PersonAdd,
  ShoppingBasket,
  Store,
} from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { DashboardActiveLocation } from 'commons/types';
import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { getWMSActiveLocations } from 'redux-stores/actions';
import { selectUser } from 'redux-stores/features/authSlice';
import { RootReducerInterface } from 'redux-stores/reducers';
import { setSelectedLocation } from 'redux-stores/reducers/dashboard.reducer';
import { AppDispatch } from 'redux-stores/store';

import { S } from './Sidebar.styles';

export interface ISidebarProps {
  currentUrl: string;
}

export const Sidebar: React.FC<ISidebarProps> = ({ currentUrl }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const { selectedLocation, activeLocations } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  useEffect(() => {
    if (!selectedLocation && user.roles.includes(UserRolesEnum.SUPER_ADMIN)) {
      dispatch(getWMSActiveLocations());
    }
  }, []);

  const handleSelectLocation = (e: SelectChangeEvent) => {
    const updatedSelectedLocation = activeLocations.find(
      (loc) => loc.warehouse_id === parseInt(e.target.value, 10),
    );
    dispatch(
      setSelectedLocation(updatedSelectedLocation as DashboardActiveLocation),
    );
  };

  return (
    <S.Sidebar>
      <S.SidebarWrapper>
        <S.SidebarHeader>
          <S.SidebarHeaderTitle>{user.userName}</S.SidebarHeaderTitle>
          <S.SidebarHeaderSubtitle>
            {/* change with option list button */}
            {!user.roles.includes(UserRolesEnum.SUPER_ADMIN) ? (
              `Location: ${user.warehouse}`
            ) : (
              <Select
                onChange={handleSelectLocation}
                value={selectedLocation?.warehouse_id.toString() || ''}
                placeholder="Location"
                style={{ width: 200, marginTop: 8 }}
              >
                {activeLocations.map((loc) => (
                  <MenuItem value={loc.warehouse_id}>{loc.name}</MenuItem>
                ))}
              </Select>
            )}
          </S.SidebarHeaderSubtitle>
        </S.SidebarHeader>
        <S.SidebarMenu>
          <S.SidebarMenuItem
            color={currentUrl === '/dashboard' ? 'primary' : 'default'}
            onClick={(): void => navigate('/dashboard')}
          >
            <Dashboard />
            Dashboard
          </S.SidebarMenuItem>
          <S.SidebarMenuItem
            color={
              currentUrl === '/dashboard/order-item' ? 'primary' : 'default'
            }
            onClick={(): void => navigate('/dashboard/order-item')}
          >
            <ShoppingBasket />
            Order List
          </S.SidebarMenuItem>
          <S.SidebarMenuItem
            color={
              currentUrl === '/dashboard/replenish-item' ? 'primary' : 'default'
            }
            onClick={(): void => navigate('/dashboard/replenish-item')}
          >
            <MoveDown />
            Replenish List
          </S.SidebarMenuItem>
          <S.SidebarMenuItem
            color={
              currentUrl === '/dashboard/stock-transfer-item'
                ? 'primary'
                : 'default'
            }
            onClick={(): void => navigate('/dashboard/stock-transfer-item')}
          >
            <OpenInFull />
            Stock Transfer List
          </S.SidebarMenuItem>
          <S.SidebarMenuItem
            color={currentUrl === '/dashboard/users' ? 'primary' : 'default'}
            onClick={(): void => navigate('/dashboard/users')}
          >
            <Person />
            User List
          </S.SidebarMenuItem>
          <S.SidebarMenuItem
            color={
              currentUrl === '/dashboard/product-upload' ? 'primary' : 'default'
            }
            onClick={(): void => navigate('/dashboard/product-upload')}
          >
            <MedicalServices />
            Product Upload
          </S.SidebarMenuItem>

          {user.roles.includes(UserRolesEnum.SUPER_ADMIN) && (
            <>
              <S.SidebarMenuItem
                color={
                  currentUrl === '/dashboard/pharmacy-upload'
                    ? 'primary'
                    : 'default'
                }
                onClick={(): void => navigate('/dashboard/pharmacy-upload')}
              >
                <Store />
                Pharmacy Upload
              </S.SidebarMenuItem>
              <S.SidebarMenuItem
                color={
                  currentUrl === '/dashboard/user-upload'
                    ? 'primary'
                    : 'default'
                }
                onClick={(): void => navigate('/dashboard/user-upload')}
              >
                <PersonAdd />
                User Upload
              </S.SidebarMenuItem>
            </>
          )}
          {user.roles.includes(UserRolesEnum.SUPER_ADMIN) && (
            <S.SidebarMenuItem
              color={
                currentUrl === '/dashboard/company-info' ? 'primary' : 'default'
              }
              onClick={(): void => navigate('/dashboard/company-info')}
            >
              <Business />
              Company Info
            </S.SidebarMenuItem>
          )}
          <S.SidebarMenuItem
            color={currentUrl === '/dashboard/bins' ? 'primary' : 'default'}
            onClick={(): void => navigate('/dashboard/bins')}
          >
            <Inventory />
            Master Bin
          </S.SidebarMenuItem>
        </S.SidebarMenu>
      </S.SidebarWrapper>
    </S.Sidebar>
  );
};
