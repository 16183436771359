import { Divider, Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class StockTransferStepJourneyStyle extends CommonComponentStyle {
  StepGridWrapper = styled(Grid)`
    padding: 8px 0 24px 0;
  `;

  StepGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;

    background: ${this.colors.LIGHTER_GRAY};
    border: 1px solid ${this.colors.PRIMARY_BLUE};
    color: ${this.colors.PRIMARY_BLUE};
    span {
      border-color: ${this.colors.PRIMARY_BLUE};
    }

    span {
      display: inline-block;
      font-size: 10px;
      width: 14px !important;
      height: 14px !important;
      line-height: 14px !important;
      text-align: center;
      border-width: 1.25px;
      border-style: solid;
      border-radius: 50%;
      margin-right: 6px;
    }

    &.current-step {
      background: ${this.colors.PRIMARY_BLUE};
      border: 1px solid ${this.colors.PRIMARY_BLUE};
      color: ${this.colors.PRIMARY_WHITE};
      span {
        border-color: ${this.colors.PRIMARY_WHITE};
      }
    }
  `;

  ConnectorGrid = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  Connector = styled(Divider)`
    width: 100%;
    border-width: 2px 0 !important;
    border-color: ${this.colors.LIGHTER_BLUE} !important;
  `;
}

export const stockTransferStepJourneyStyle =
  new StockTransferStepJourneyStyle();
