import React from 'react';

import { Button } from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { BinTableProps, ProductDetail } from 'commons/types';
import { RowTitleProps } from 'commons/types/listPageData.interface';
import { TableActionRow, DataTable } from 'pages/dashboard/commons/components';

const rowTitles: RowTitleProps[] = [
  { title: 'Bin' },
  { title: 'Type' },
  { title: 'SKU Code' },
];

const Row: React.FC<{
  data: ProductDetail;
}> = ({ data }) => {
  return (
    <S.TableRow>
      <S.TableColumn>{data.bin}</S.TableColumn>
      <S.TableColumn style={{ textTransform: 'capitalize' }}>
        {data.bin_type.replace('_', ' ')}
      </S.TableColumn>
      <S.TableColumn>{data.sku_code}</S.TableColumn>
    </S.TableRow>
  );
};

const BinTable: React.FC<BinTableProps> = (params): React.ReactNode => {
  const { data = [], onClickCreate }: BinTableProps = params;

  return (
    <>
      <TableActionRow>
        <Button onClick={onClickCreate} variant="outlined" fullWidth>
          Create New Bin
        </Button>
      </TableActionRow>
      <DataTable rowTitles={rowTitles} hideCheckbox>
        <S.TableBody>
          {data.map((item) => (
            <Row data={item} />
          ))}
        </S.TableBody>
      </DataTable>
    </>
  );
};

export default BinTable;
