import React, { useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { FileImportType } from 'commons/enums';
import { getBulkUploadHistoryAction } from 'redux-stores/actions';

import { FileImportTab, ListPage } from '../commons/components';

import { PharmacyCopyInvoiceFlagBulkUploadTemplate } from './templates';

const PharmacyUploadPage: React.FC = () => {
  const { updateQueryParams } = useUpdateQueryParams();

  const updatePharmacyUploadHistoryPageDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getBulkUploadHistoryAction({
          searchParams: _searchParams,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  useEffect(() => {
    updateQueryParams(
      { file_import_type: FileImportType.PHARMACY_COPY_INVOICE_FLAG },
      { clearQueryParams: ['page'] },
    );
  }, []);

  return (
    <ListPage
      updateListSearchDebounce={updatePharmacyUploadHistoryPageDebounce}
      pageSize="5"
    >
      <FileImportTab
        onDownloadTemplateClick={() => {
          PharmacyCopyInvoiceFlagBulkUploadTemplate.createLink().click();
        }}
        downloadTemplateBtnText="Pharmacy Copy Invoice Flag"
        uploadType={FileImportType.PHARMACY_COPY_INVOICE_FLAG}
      />
    </ListPage>
  );
};

export default PharmacyUploadPage;
