/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  IStockTransfer,
  ProductDetail,
  skuCode,
  StockTransferState,
} from 'commons/types';

const initialState: StockTransferState = {
  stockTransfer: {},
  stockTransferSkuList: [],
  binRecommendationInfo: [],
};

const stockTransferSlice = createSlice({
  name: 'stockTransfer',
  initialState,
  reducers: {
    setStockTransfers(
      state: StockTransferState,
      action: { type: string; payload: IStockTransfer[] },
    ) {
      const newStockTransfer: Record<skuCode, IStockTransfer> = {};
      const newStockTransferSkuList: skuCode[] = [];
      action.payload.forEach((stockTransferItem) => {
        newStockTransfer[stockTransferItem.sku_code] = stockTransferItem;
        newStockTransferSkuList.push(stockTransferItem.sku_code);
      });
      state.stockTransfer = newStockTransfer;
      state.stockTransferSkuList = newStockTransferSkuList;
    },

    setUpdateStockTransfer(
      state: StockTransferState,
      action: { type: string; payload: IStockTransfer },
    ) {
      state.stockTransfer[action.payload.sku_code] = action.payload;
      if (state.stockTransferSkuList.length === 0) {
        state.stockTransferSkuList = [action.payload.sku_code];
      }
    },

    removeStockTransfer(
      state: StockTransferState,
      action: { type: string; payload: IStockTransfer },
    ) {
      delete state.stockTransfer[action.payload.sku_code];
    },

    setBinRecommendationInfo(
      state: StockTransferState,
      action: { type: string; payload: ProductDetail[] },
    ) {
      state.binRecommendationInfo = action.payload;
    },
  },
});

export const {
  setStockTransfers,
  setUpdateStockTransfer,
  removeStockTransfer,
  setBinRecommendationInfo,
} = stockTransferSlice.actions;

export const stockTransferReducer = stockTransferSlice.reducer;
