import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User, UserState } from 'commons/types/user.interface';
import { Keycloak } from 'services/keycloak/Keycloak.class';

const initialState: UserState = {
  user: {
    isAuthenticated: false,
    userName: '',
    roles: [],
    warehouse: '',
    warehouseId: 0,
  }, // for user object
};

const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    logout: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.user = initialState.user;
      window.location.href = Keycloak.getLogoutUrl();
    },
    login: (state, action: PayloadAction<User>) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
      console.log(`login: ${JSON.stringify(state.user)}`);
    },
    relogin: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.user = initialState.user;
      // TODO clear local storage
      localStorage.clear();
      window.location.href = '/login';
    },
  },
});

export const { login, logout, relogin } = authSlice.actions;

// export default authSlice.reducer

export const authReducer = authSlice.reducer;

export const selectUser = (state: any): User => state.auth.user;
