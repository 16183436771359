import { BinType } from 'commons/enums';
import {
  IStockTransfer,
  IStockTransferItemDestination,
  IStockTransferItemProps,
  ProductDetail,
} from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  IGetBinTypeFromBinNameRequest,
  IGetProductDetailRequest,
  IGetStockTransferBySkuCodeRequest,
  IGetStockTransferBySkuCodeResponse,
  IPostPickSourceBinRequest,
  IStockTransferCancelReq,
  IStockTransferCompleteReq,
  IStockTransferCreateReq,
} from './types';
import { IPostPutDestinationRequest } from './types/post-put-destination.type';
import { IPostRemoveBatchRequest } from './types/post-remove-batch.type';

class SwipeRxWmsApiStockTransfer extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.STOCK_TRANSFER);
  }

  async getStockTransfers(): Promise<IStockTransfer[]> {
    const response = await this.get(`${this.apiResource}`);
    if (!response.data) throw new Error('Data not found');
    return response.data;
  }

  async getStockTransferBySkuCode(
    params: IGetStockTransferBySkuCodeRequest,
  ): Promise<IGetStockTransferBySkuCodeResponse> {
    const response = await this.get(
      `${this.apiResource}/get-by-sku-code`,
      params,
    );
    if (!response.data) throw new Error('Data not found');
    return response.data;
  }

  async addNewStockTransfer(
    payload: IStockTransferCreateReq,
  ): Promise<IStockTransfer> {
    const response = await this.post(`${this.apiResource}/create`, payload);
    if (!response.data) throw new Error('Data not found');
    return response.data as IStockTransfer;
  }

  async getProductDetail(
    skuCode: string,
    bin: string,
    inventoryNumber: string,
    isReturnEmptyProductOnNotFound = false,
  ): Promise<IStockTransferItemProps> {
    const query: IGetProductDetailRequest = {
      bin,
      sku_code: skuCode,
      inventory_number: inventoryNumber,
      is_return_empty_product_on_not_found: isReturnEmptyProductOnNotFound,
    };
    const result = await this.get(`${this.apiResource}/product-detail`, query);
    if (result && result.data) {
      return result.data;
    }
    throw new Error('Failed to get product detail');
  }

  async getBinTypeFromBinName(skuCode: string, bin: string): Promise<string> {
    const query: IGetBinTypeFromBinNameRequest = {
      bin,
      sku_code: skuCode,
    };
    const result = await this.get(`${this.apiResource}/bin-type`, query);
    if (result && result.data) {
      return result.data;
    }
    throw new Error('Failed to get product detail');
  }

  async postPickSourceBin(
    skuCode: string,
    sourceBin: IStockTransferItemProps,
  ): Promise<void> {
    const payload: IPostPickSourceBinRequest = {
      sku_code: skuCode,
      bin: sourceBin.bin,
      inventory_number: sourceBin.inventory_number,
      quantity: sourceBin.quantity,
      expiry_date: sourceBin.expiry_date,
    };
    await this.post(`${this.apiResource}/source/pick`, payload);
  }

  async postRemoveSourceBatch(
    skuCode: string,
    sourceBin: Pick<IStockTransferItemProps, 'bin' | 'inventory_number'>,
  ): Promise<void> {
    const payload: IPostRemoveBatchRequest = {
      sku_code: skuCode,
      bin: sourceBin.bin,
      inventory_number: sourceBin.inventory_number,
    };
    await this.post(`${this.apiResource}/source/remove-batch`, payload);
  }

  async postRemoveStockTransferDestinationBatch(
    skuCode: string,
    destinationBin: Pick<
      IStockTransferItemDestination,
      'bin' | 'inventory_number'
    >,
  ): Promise<void> {
    const payload: IPostRemoveBatchRequest = {
      sku_code: skuCode,
      bin: destinationBin.bin,
      inventory_number: destinationBin.inventory_number,
    };
    await this.post(`${this.apiResource}/destination/remove-batch`, payload);
  }

  async postPutDestinationBin(
    skuCode: string,
    destinationBin: IStockTransferItemDestination,
  ): Promise<void> {
    const payload: IPostPutDestinationRequest = {
      sku_code: skuCode,
      bin: destinationBin.bin,
      bin_type: destinationBin.bin_type,
      inventory_number: destinationBin.inventory_number,
      quantity: destinationBin.quantity,
      expiry_date: destinationBin.expiry_date,
      is_revoked_bin: destinationBin.is_revoked_bin,
    };

    await this.post(`${this.apiResource}/destination/put`, payload);
  }

  async cancelStockTransfers(
    payload: IStockTransferCancelReq,
  ): Promise<IStockTransfer> {
    const response = await this.post(`${this.apiResource}/cancel`, payload);
    if (!response.data) throw new Error('Data not found');
    return response.data;
  }

  async completeStockTransfers(
    payload: IStockTransferCompleteReq,
  ): Promise<void> {
    await this.post(`${this.apiResource}/complete`, payload);
  }

  async getBinRecommendationInfo(
    skuCode: string,
    binType: BinType,
  ): Promise<ProductDetail[]> {
    const result = await this.get(
      `${this.apiResource}/${skuCode}/bin-recommendation/${binType}/info`,
    );

    return result.data;
  }
}

export const swipeRxWmsApiStockTransfer = new SwipeRxWmsApiStockTransfer();
