import { useSelector } from 'react-redux';

import { userAdminGroup } from 'commons/types/userGroup';
import { selectUser } from 'redux-stores/features/authSlice';

export function CheckUserRoles(rolesGroup: string[]): boolean {
  const user = useSelector(selectUser);
  const userRoles = user.roles;
  const allRoles = [...rolesGroup, ...userAdminGroup];
  return userRoles.some((role) => allRoles.includes(role));
}
