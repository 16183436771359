/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { FileImportType } from 'commons/enums';
import { getBulkUploadHistoryAction } from 'redux-stores/actions';

import { CustomTabPanel, FileImportTab, ListPage } from '../commons/components';

import {
  ProductBinQtyBulkUploadTemplate,
  ProductDimensionBulkUploadTemplate,
  ProductPrioritizationCategoryBulkUploadTemplate,
} from './templates';

const ProductUploadPage: React.FC = () => {
  const { updateQueryParams } = useUpdateQueryParams();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const updateProductUploadHistoryPageDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getBulkUploadHistoryAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  useEffect(() => {
    updateQueryParams(
      { file_import_type: FileImportType.PRODUCT_BIN_QTY },
      { clearQueryParams: ['page'] },
    );
  }, []);

  return (
    <ListPage
      updateListSearchDebounce={updateProductUploadHistoryPageDebounce}
      pageSize="5"
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="Min/Max Bin Qty" />
          <Tab label="Prioritization Category" />
          <Tab label="Dimension" />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <FileImportTab
          onDownloadTemplateClick={() => {
            ProductBinQtyBulkUploadTemplate.createLink().click();
          }}
          downloadTemplateBtnText="Product Min/Max Bin Qty"
          uploadType={FileImportType.PRODUCT_BIN_QTY}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <FileImportTab
          onDownloadTemplateClick={() => {
            ProductPrioritizationCategoryBulkUploadTemplate.createLink().click();
          }}
          downloadTemplateBtnText="Product Prioritization Category"
          uploadType={FileImportType.PRODUCT_CATEGORY_PRIORITIZATION}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <FileImportTab
          onDownloadTemplateClick={() => {
            ProductDimensionBulkUploadTemplate.createLink().click();
          }}
          downloadTemplateBtnText="Product Dimension"
          uploadType={FileImportType.PRODUCT_DIMENSION}
        />
      </CustomTabPanel>
    </ListPage>
  );
};

export default ProductUploadPage;
