import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Brightness1 } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import { ReplenishTaskProgressEnum } from 'commons/enums';
import { ReplenishTask } from 'commons/types';
import {
  getReplenishTasksAction,
  setInitReplenishTaskSource,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { sourceConfig } from './config';
import S from './ReplenishListPage.styles';

export const ReplenishListSourcePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.replenishTask,
  );

  const [filter, setFilter] = useState<string>('');
  const [isProceedButtonDisabled, setProceedButtonDisabled] = useState(false);
  const [displayedTasks, setDisplayedTasks] =
    useState<ReplenishTask[]>(assignedTasks);

  useEffect(() => {
    dispatch(getReplenishTasksAction({ withRecommendation: true }));
  }, [dispatch]);

  const _setDisplayedTaskWithAdditional = (tasks: ReplenishTask[]) => {
    const unfulfilledTasks: ReplenishTask[] = [];
    const partiallyFulfilledTasks: ReplenishTask[] = [];
    const fulfilledTasks: ReplenishTask[] = [];
    tasks.forEach((assignedTask) => {
      const task = structuredClone(assignedTask);

      task.quantity_picked = task.source_bins.reduce(
        (sum, sourceBin) => sum + sourceBin.quantity,
        0,
      );

      if (task.quantity_picked === 0) {
        task.progress = ReplenishTaskProgressEnum.UNFULFILLED;
        unfulfilledTasks.push(task);
      } else if (task.quantity_picked === task.quantity) {
        task.progress = ReplenishTaskProgressEnum.FULFILLED;
        fulfilledTasks.push(task);
      } else {
        task.progress = ReplenishTaskProgressEnum.PARTIALLY_FULFILLED;
        partiallyFulfilledTasks.push(task);
      }
    });

    setDisplayedTasks(
      unfulfilledTasks.concat(partiallyFulfilledTasks, fulfilledTasks),
    );
  };

  useMemo(() => {
    _setDisplayedTaskWithAdditional(assignedTasks);
  }, [assignedTasks]);

  useMemo(() => {
    setProceedButtonDisabled(
      displayedTasks.filter(
        (task) =>
          task.progress &&
          [
            ReplenishTaskProgressEnum.PARTIALLY_FULFILLED,
            ReplenishTaskProgressEnum.FULFILLED,
          ].includes(task.progress),
      ).length === 0 ||
        displayedTasks.filter(
          (task) =>
            task.source_bins.reduce((total, bin) => bin.quantity + total, 0) >
            task.destination_bin_requested.reduce(
              (total, bin) => bin.requested_quantity + total,
              0,
            ),
        ).length > 0,
    );
  }, [displayedTasks]);

  const onFilterChange = (value: string): void => {
    setFilter(value);
    if (value === '') {
      _setDisplayedTaskWithAdditional(assignedTasks);
    } else {
      const filteredTasks = assignedTasks.filter((task) => {
        if (task.sku_code.includes(value)) {
          return true;
        }
        return false;
      });
      _setDisplayedTaskWithAdditional(filteredTasks);
    }
  };

  const handleBackToAssignSKUPage = (): void => {
    navigate('/replenish/assign');
  };

  const renderList = (replenishList: ReplenishTask[]) =>
    replenishList.map((data, idx) => {
      const { progress } = data;
      const colorProgress =
        progress === ReplenishTaskProgressEnum.UNFULFILLED
          ? S.colors.PROGRESS_ICON_COLOR[0]
          : progress === ReplenishTaskProgressEnum.FULFILLED
          ? S.colors.PROGRESS_ICON_COLOR[1]
          : S.colors.PROGRESS_ICON_COLOR[2];
      const skuButtonText =
        progress === ReplenishTaskProgressEnum.UNFULFILLED ? 'START' : 'EDIT';

      return (
        <Grid container>
          <S.SKUGridWrapper container>
            <S.SKUGrid item xs={8}>
              SKU{' '}
              <span data-testid={`TextReplenishListSourceSKU-${idx + 1}`}>
                {data.sku_code}
              </span>
              <S.ProgressSpan
                data-testid={`TextReplenishListSourceQtyProgress-${idx + 1}`}
              >
                <Brightness1 style={{ fontSize: 11, color: colorProgress }} />{' '}
                {data.quantity_picked ?? 0}/{data.quantity}
              </S.ProgressSpan>
            </S.SKUGrid>
            <S.SKUButtonGrid item xs={4}>
              <S.SecondaryButton
                onClick={() => {
                  dispatch(setInitReplenishTaskSource()).then(() => {
                    navigate(`/replenish/task/source/${data.sku_code}`);
                  });
                }}
                data-testid={`BtnReplenishListSourceStart-${idx + 1}`}
              >
                {skuButtonText}
              </S.SecondaryButton>
            </S.SKUButtonGrid>
          </S.SKUGridWrapper>
          <S.TableInfoGridWrapper container>
            <S.TableInfoGrid item xs={12}>
              <TableComponent
                config={sourceConfig}
                data={data.source_bin_recommendations}
              />
            </S.TableInfoGrid>
          </S.TableInfoGridWrapper>
        </Grid>
      );
    });

  return (
    <div>
      <Header
        title="Replenish List"
        prevPageHandler={handleBackToAssignSKUPage}
      />
      {/* Content Section */}
      <S.ContentWrapper>
        <S.StepGridWrapper container>
          <S.StepGrid item xs={5} className="current-step">
            <span>1</span>
            Source Bin
          </S.StepGrid>
          <S.ConnectorGrid item xs={2}>
            <S.Connector />
          </S.ConnectorGrid>
          <S.StepGrid item xs={5}>
            <span>2</span> Destination Bin
          </S.StepGrid>
        </S.StepGridWrapper>
        <S.SearchBar
          placeholder="SKU Number or Scan"
          variant="outlined"
          value={filter}
          onChange={(event) => onFilterChange(event.target.value)}
          inputProps={{
            'data-testid': 'InputReplenishListSourceSKU',
          }}
        />

        {renderList(displayedTasks)}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton
          disabled={isProceedButtonDisabled}
          onClick={() => navigate('/replenish/list/destination')}
          data-testid="BtnReplenishListSourceProceed"
        >
          PROCEED TO NEXT STEP
        </S.PrimaryButton>
      </S.FooterWrapper>
    </div>
  );
};
