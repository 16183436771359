/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  DashboardState,
  Order,
  ReplenishTask,
  User,
  IStockTransfer,
  DashboardActiveOrderSummaries,
  DashboardShiftSummaries,
  DashboardSetHighPriority,
  DashboardActiveLocation,
  IFileImport,
  DashboardSetHighPriorityReplenish,
  ProductDetail,
} from 'commons/types';
import { ReducerKeys } from 'redux-stores/enums/reducerKey.enum';

const initialState: DashboardState = {
  orderList: [],
  replenishList: [],
  summaries: {
    activeOrderSummaries: {
      orderCounts: {
        regular: {
          in_queue: 0,
          in_progress: 0,
        },
        alkes: {
          in_queue: 0,
          in_progress: 0,
        },
        precursor: {
          in_queue: 0,
          in_progress: 0,
        },
      },
      currentActiveUserCount: 0,
    },
    shiftSummaries: {
      orderPerShifts: [],
      topUsers: [],
    },
  },
  userList: [],
  binList: [],
  stockTransferList: [],
  activeLocations: [],
  selectedLocation: null,
  bulkUploadHistory: [],
  updateListPage: false,
};

const dashboardSlice = createSlice({
  initialState,
  name: ReducerKeys.Dashboard,
  reducers: {
    setAllOrderLists(
      state: DashboardState,
      action: { type: string; payload: Order[] },
    ) {
      state.orderList = action.payload;
    },
    setAllReplenishLists(
      state: DashboardState,
      action: { type: string; payload: ReplenishTask[] },
    ) {
      state.replenishList = action.payload;
    },
    setAllUserLists(
      state: DashboardState,
      action: { type: string; payload: User[] },
    ) {
      state.userList = action.payload;
    },
    setAllBinLists(
      state: DashboardState,
      action: { type: string; payload: ProductDetail[] },
    ) {
      state.binList = action.payload;
    },
    setAllStockTransferLists(
      state: DashboardState,
      action: { type: string; payload: IStockTransfer[] },
    ) {
      state.stockTransferList = action.payload;
    },
    setSummariesActiveOrder(
      state: DashboardState,
      action: { type: string; payload: DashboardActiveOrderSummaries },
    ) {
      state.summaries.activeOrderSummaries = action.payload;
    },
    setSummariesShifts(
      state: DashboardState,
      action: { type: string; payload: DashboardShiftSummaries },
    ) {
      state.summaries.shiftSummaries = action.payload;
    },
    setHighPriorityValue(
      state: DashboardState,
      action: { type: string; payload: DashboardSetHighPriority },
    ) {
      const { payload } = action;
      const newOrderList = state.orderList.map((order) => {
        if (payload.po_numbers.includes(order.po_number))
          return { ...order, is_high_priority: payload.value };

        return order;
      });
      state.orderList = newOrderList;
    },
    setSelectedLocation(
      state: DashboardState,
      action: { type: string; payload: DashboardActiveLocation },
    ) {
      state.selectedLocation = action.payload;
    },
    setActiveLocations(
      state: DashboardState,
      action: { type: string; payload: DashboardActiveLocation[] },
    ) {
      state.activeLocations = action.payload;

      // set selected if null
      if (!state.selectedLocation)
        state.selectedLocation = { ...action.payload[0] };
    },
    setBulkUploadHistory(
      state: DashboardState,
      action: { type: string; payload: IFileImport[] },
    ) {
      state.bulkUploadHistory = action.payload;
    },
    setUpdateListPage(state: DashboardState) {
      state.updateListPage = !state.updateListPage;
    },
    setHighPriorityValueReplenish(
      state: DashboardState,
      action: {
        type: string;
        payload: DashboardSetHighPriorityReplenish;
      },
    ) {
      const { payload } = action;
      const newReplenishList = state.replenishList.map((replenish) => {
        if (payload.replenish_numbers.includes(replenish.replenish_number))
          return { ...replenish, is_high_priority: payload.value };
        return replenish;
      });
      state.replenishList = newReplenishList;
    },
  },
});

export const {
  setAllOrderLists,
  setAllReplenishLists,
  setAllUserLists,
  setAllBinLists,
  setAllStockTransferLists,
  setSummariesActiveOrder,
  setSummariesShifts,
  setHighPriorityValue,
  setSelectedLocation,
  setActiveLocations,
  setBulkUploadHistory,
  setUpdateListPage,
  setHighPriorityValueReplenish,
} = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
