import { UserRolesEnum } from './user.roles.enum';

export const userPickerGroup = [
  UserRolesEnum.PICKER,
  UserRolesEnum.PICKER_SPV,
  UserRolesEnum.PICKER_LEAD,
];

export const userPackerGroup = [
  UserRolesEnum.PACKER,
  UserRolesEnum.PACKER_SPV,
  UserRolesEnum.PACKER_LEAD,
];

export const userAdminGroup = [UserRolesEnum.ADMIN, UserRolesEnum.SUPER_ADMIN];
