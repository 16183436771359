import { NavigateFunction } from 'react-router-dom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { PRIMARY_GREEN, PRIMARY_RED } from 'commons/styles/colors';
import {
  BasketProductDetails,
  KoliInfo,
} from 'commons/types/packingTask.interface';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import {
  checkItem,
  setPackingTask,
} from 'redux-stores/reducers/packingTaskReducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swipeRxWmsAPIPackingTask } from 'services/apis/SwipeRxWmsApiPackingTask';
import { swipeRxWmsAPIPackingUpload } from 'services/apis/SwipeRxWmsApiPackingUpload';

// TODO: preserve this method until before release
// to backup ui development keep going if api have refactor
export const getPackingTaskDummy = createAsyncThunk(
  'packingTask/getPackingTaskDummy',
  async (_, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.getPackingTaskDummy();
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getPackingTaskByBasket = createAsyncThunk(
  'packingTask/getPackingTaskByBasket',
  async (basket: number, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getPackingTaskByBasket(basket);
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getPackerOnProgressPackingTask = createAsyncThunk(
  'packingTask/getPackerOnProgressPackingTask',
  async (params: { poNumber: string }, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getPackerOnProgressPackingTask(
          params.poNumber,
        );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getPackerOnProgressPackingTaskAllowOnUpdate = createAsyncThunk(
  'packingTask/getPackerOnProgressPackingTask',
  async (
    params: {
      poNumber: string;
    },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getPackerOnProgressPackingTaskAllowOnUpdate(
          params.poNumber,
        );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const uploadBasketPhoto = createAsyncThunk(
  'packingTask/uploadBasketPhoto',
  async (
    params: {
      poNumber: string;
      basket: number;
      imageSrc: string;
      onClose: () => void;
    },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const SIZE_LIMIT = 1024 * 1024 * 2; // 2MB
      const blob = await fetch(params.imageSrc).then((r) => r.blob());

      if (blob.size > SIZE_LIMIT) {
        dispatch(
          snackbarSetData({
            open: true,
            message: 'File is too large',
            color: PRIMARY_RED,
          }),
        );
        return null;
      }

      const formData = new FormData();
      formData.append('file', blob, 'basket.jpg');

      const response = await swipeRxWmsAPIPackingUpload.uploadPhoto(
        params.poNumber,
        params.basket,
        formData,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
        params.onClose();
      }
      return response.data;
    }, dispatch);
  },
);

export const deleteBasketPhoto = createAsyncThunk(
  'packingTask/deleteBasketPhoto',
  async (
    params: {
      poNumber: string;
      basket: number;
      photoLink: string;
    },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingUpload.deleteBasketPhoto(
        params.poNumber,
        params.basket,
        params.photoLink,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const updateQtyPicked = createAsyncThunk(
  'packingTask/updateQtyPicked',
  async (
    params: {
      poNumber: string;
      payload: BasketProductDetails & { basket: number };
      onClose: () => void;
    },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateQtyPicked(
        params.poNumber,
        params.payload,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
        params.onClose();
      }
      return response.data;
    }, dispatch);
  },
);

export const checkItemAction = createAsyncThunk(
  'packingTask/checkItem',
  async (
    params: {
      poNumber: string;
      basket: number;
      skuCode: string;
      isChecked: boolean;
      basketIndex: number;
      itemIndex: number;
    },
    { dispatch },
  ) => {
    const { poNumber, basket, skuCode, isChecked, basketIndex, itemIndex } =
      params;

    return defaultActionProcess(async () => {
      dispatch(
        checkItem({
          basketIndex,
          itemIndex,
          isChecked,
        }),
      );
      try {
        await swipeRxWmsAPIPackingTask.checkBasketInfo({
          po_number: poNumber,
          basket,
          sku_code: skuCode,
          is_checked: isChecked,
        });
      } catch (error: any) {
        dispatch(
          checkItem({
            basketIndex,
            itemIndex,
            isChecked: !isChecked,
          }),
        );
        throw new Error(error.message);
      }
    }, dispatch);
  },
);

export const completeChecking = createAsyncThunk(
  'packingTask/completeChecking',
  async (payload: { poNumber: string }, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.completeChecking(
        payload.poNumber,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const updateKoliInfo = createAsyncThunk(
  'packingTask/updateKoliInfo',
  async (payload: { poNumber: string; koli: KoliInfo[] }, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateKoliInfo(
        payload.poNumber,
        payload.koli,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getAllocationStagingSuggestion = createAsyncThunk(
  'packingTask/getAllocationStagingSuggestion',
  async (
    payload: { warehouseId: number },
    { dispatch },
  ): Promise<string | null | undefined> => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getAllocationStagingSuggestion(
          payload.warehouseId,
        );
      return response.data;
    }, dispatch);
  },
);

export const updateAllocationStaging = createAsyncThunk(
  'packingTask/updateAllocationStaging',
  async (
    payload: { poNumber: string; allocationStaging: string | null },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateAllocationStaging(
        payload.poNumber,
        payload.allocationStaging,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const completePacking = createAsyncThunk(
  'packingTask/completePacking',
  async (
    payload: { poNumber: string; navigate: NavigateFunction },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      await swipeRxWmsAPIPackingTask.completePacking(payload.poNumber);
      dispatch(setPackingTask(null));
      payload.navigate('/home');
    }, dispatch);
  },
);

export const resyncInvoice = createAsyncThunk(
  'packingTask/resyncInvoice',
  async (payload: { poNumber: string }, { dispatch }) => {
    return defaultActionProcess(async () => {
      const { poNumber } = payload;
      await swipeRxWmsAPIPackingTask.resyncInvoice(poNumber);
      dispatch(getPackerOnProgressPackingTaskAllowOnUpdate({ poNumber }));
      dispatch(
        snackbarSetData({
          open: true,
          message: `Synced invoice for PO number ${poNumber}`,
          color: PRIMARY_GREEN,
        }),
      );
    }, dispatch);
  },
);
