import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { userPackerGroup, userPickerGroup } from 'commons/types/userGroup';
import { CheckUserRoles } from 'commons/utils/checkUserRoles';

import UserInfoHeader from './components/UserInfoHeader.component';
import S from './HomePage.styles';

const HomePageOutbound: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToHomePage = (): void => {
    navigate('/');
  };

  return (
    <div>
      <UserInfoHeader title="Outbound" prevPageHandler={handleBackToHomePage} />

      {/* Menu Options */}
      {/* Picking */}
      {CheckUserRoles(userPickerGroup) ? (
        <S.MenuOption
          onClick={(): void => navigate('/pick-task/assign')}
          data-testid="BtnMenuPicking"
        >
          <Grid container>
            <Grid item xs={2}>
              <S.MenuIcon src="/assets/picking-icon.svg" alt="picking-icon" />
            </Grid>
            <Grid item xs={8}>
              <S.MenuText>Picking</S.MenuText>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIos
                fontSize="small"
                style={{ marginTop: 14, textAlign: 'right' }}
              />
            </Grid>
          </Grid>
        </S.MenuOption>
      ) : null}

      {/* Checking and packing */}
      {CheckUserRoles(userPackerGroup) ? (
        <S.MenuOption
          onClick={() => navigate('/pack-task/check-basket')}
          data-testid="BtnMenuCheckingPacking"
        >
          <Grid container>
            <Grid item xs={2}>
              <S.MenuIcon src="/assets/checking-icon.svg" alt="checking-icon" />
            </Grid>
            <Grid item xs={8}>
              <S.MenuText>Checking & Packing</S.MenuText>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIos
                fontSize="small"
                style={{ marginTop: 14, textAlign: 'right' }}
              />
            </Grid>
          </Grid>
        </S.MenuOption>
      ) : null}
    </div>
  );
};

export default HomePageOutbound;
