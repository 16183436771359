import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';
import { Button, capitalize, Grid, TextField } from '@mui/material';

import { Header } from 'commons/components/Header';
import { OrderStatusEnum, PrintPDFTypeEnum } from 'commons/enums';
import { KoliInfoUI, OrderType } from 'commons/types';
import { printPDF } from 'commons/utils/printPackingTaskPDF.util';
import { getCompanyInfo } from 'redux-stores/actions/companyInfo.action';
import {
  getPackerOnProgressPackingTaskAllowOnUpdate,
  resyncInvoice,
  updateKoliInfo,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { StationNumberDialog } from '../commons/components/StationNumberDialog';

import S from './PackPrintPage.styles';

const allowedStatus = [
  OrderStatusEnum.PACKING,
  OrderStatusEnum.UPDATING,
  OrderStatusEnum.UPDATE_FAILED,
];
const allowedStatusForRecheck = [
  OrderStatusEnum.UPDATING,
  OrderStatusEnum.UPDATE_FAILED,
];
const intervalTimeinSeconds = 5;

const PackPrintPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [searchParams, setSearchParams] = useSearchParams();
  const [koli, setKoli] = useState<KoliInfoUI[]>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [isPrinted, setIsPrinted] = useState<{
    [K in PrintPDFTypeEnum]: boolean;
  }>({
    [PrintPDFTypeEnum.INVOICE_TTF]: false,
    [PrintPDFTypeEnum.ESP]: false,
    [PrintPDFTypeEnum.SHIPPING_LABEL]: false,
  });
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const { company } = useSelector(
    (state: RootReducerInterface) => state.company,
  );

  useEffect(() => {
    const poNumber = searchParams.get('poNumber') ?? '';
    if (poNumber === '') {
      navigate('/pack-task/check-basket');
      return;
    }
    if (!packingTask) {
      dispatch(getPackerOnProgressPackingTaskAllowOnUpdate({ poNumber })).then(
        () => setDataFetched(true),
      );
    } else {
      const koliUI = packingTask.koli.map((k) => ({
        weight: k.weight.toString(),
      }));
      if (koliUI.length === 0) {
        koliUI.push({ weight: '1' });
      }
      setKoli(koliUI);
    }
    if (!company) {
      dispatch(getCompanyInfo({}));
    }
  }, [packingTask, company, dispatch]);

  const [countdown, setCountdown] = useState(intervalTimeinSeconds);
  useEffect(() => {
    if (
      packingTask?.picking_task?.status &&
      allowedStatusForRecheck.includes(packingTask.picking_task.status)
    ) {
      const interval = setInterval(() => {
        dispatch(
          getPackerOnProgressPackingTaskAllowOnUpdate({
            poNumber: packingTask.po_number,
          }),
        );
        setCountdown(intervalTimeinSeconds);
      }, intervalTimeinSeconds * 1000);

      return () => clearInterval(interval);
    }
    // for return type consistency
    return () => {};
  }, [dispatch, packingTask]);

  useEffect(() => {
    if (
      packingTask?.picking_task?.status &&
      allowedStatusForRecheck.includes(packingTask.picking_task.status)
    ) {
      const interval = setInterval(() => {
        setCountdown(countdown - 1 >= 0 ? countdown - 1 : 0);
      }, 1000);

      return () => clearInterval(interval);
    }
    // for return type consistency
    return () => {};
  }, [countdown, packingTask]);

  /** Early return logic */
  if (!packingTask && dataFetched) navigate('/pack-task/check-basket');
  if (!packingTask) return <div />;
  if (
    !packingTask.picking_task.status ||
    !allowedStatus.includes(packingTask.picking_task.status)
  ) {
    navigate('/pack-task/check-basket');
    return <div />;
  }

  const setKoliNumber = (number: number) => {
    const clonedKoli = structuredClone(koli);
    if (number >= koli.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < number - koli.length; i++) {
        clonedKoli.push({ weight: '' });
      }
    } else {
      const removedAmt = koli.length - number;
      clonedKoli.splice(number, removedAmt);
    }
    setKoli(clonedKoli);
    // set is printed to false, because koli value changes
    setIsPrinted({ ...isPrinted, [PrintPDFTypeEnum.SHIPPING_LABEL]: false });
  };

  const setKoliInfo = (index: number, weight: string) => {
    const clonedKoli = structuredClone(koli);
    clonedKoli[index] = { weight };
    setKoli(clonedKoli);
  };

  const proceedToNextPage = () => {
    // update koli info to API
    const updatedKoli = koli.map((k) => ({
      weight: parseFloat(k.weight) || 0,
    }));
    dispatch(
      updateKoliInfo({
        poNumber: packingTask.po_number,
        koli: updatedKoli,
      }),
    ).then(() => {
      navigate({
        pathname: `/pack-task/summary/`,
        search: createSearchParams({
          poNumber: packingTask.po_number,
        }).toString(),
      });
    });
  };

  const proceedToPrevPage = () => {
    navigate({
      pathname: `/pack-task/check-basket/`,
      search: createSearchParams({
        poNumber: packingTask.po_number,
      }).toString(),
    });
  };

  const printPackingTaskPDF = async (type: PrintPDFTypeEnum) => {
    printPDF(packingTask, company, koli, type, dispatch);

    // mark isPrinted
    setIsPrinted({ ...isPrinted, [type]: true });
  };

  return (
    <div>
      <Header title="Packing" prevPageHandler={proceedToPrevPage} />

      {/* Page Content */}
      <Grid container>
        {/* Task Information */}
        <Grid item xs={12}>
          <S.MainInfoWrapper container>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">PO Number</p>
              <p data-testid="TextPackingPO">{packingTask?.po_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Invoice Number</p>
              <p data-testid="TextPackingInvoice">
                {packingTask?.picking_task.invoice?.invoice_number || '-'}
              </p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Picker</p>
              <p data-testid="TextPackingPicker">
                {packingTask?.picking_task.picker}
              </p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">3PL</p>
              <p data-testid="TextPacking3PL">
                {packingTask?.picking_task.logistic_partner_name}
              </p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Priority Type</p>
              <S.PriorityText data-testid="TextPackingPriority">
                {packingTask?.picking_task.priority}
              </S.PriorityText>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Pharmacy Name</p>
              <p data-testid="TextPackingPharmacy">
                {packingTask?.picking_task.pharmacy?.name || '-'}
              </p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title" data-testid="TextPackingStatus">
                Status
              </p>
              <p>
                {capitalize(
                  packingTask?.picking_task.status.replace('-', ' '),
                ) || '-'}
                {allowedStatusForRecheck.includes(
                  packingTask?.picking_task.status,
                )
                  ? `... ${countdown}`
                  : ''}
              </p>
            </S.MainInfoItem>
          </S.MainInfoWrapper>
        </Grid>

        {/* Printing Section */}
        <Grid item xs={12}>
          <S.PrintInfoWrapper container>
            <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
              <CheckCircle
                sx={{
                  color: isPrinted[PrintPDFTypeEnum.INVOICE_TTF]
                    ? 'primary.main'
                    : 'primary.light',
                }}
                style={{ marginRight: 16 }}
              />
              <div>
                <b>PRINT DOCUMENTS</b>
                {isPrinted['invoice-ttf'] && (
                  <Button
                    onClick={() =>
                      dispatch(
                        resyncInvoice({ poNumber: packingTask.po_number }),
                      )
                    }
                    style={{ padding: '0 8px' }}
                  >
                    Resync Invoice
                  </Button>
                )}{' '}
                <br /> <br />
                Documents include: Invoice and TTF
              </div>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={3} sm={2} className="align-right">
              <S.PrimaryButton
                onClick={() =>
                  printPackingTaskPDF(PrintPDFTypeEnum.INVOICE_TTF)
                }
                disabled={
                  packingTask.picking_task.status !== OrderStatusEnum.PACKING
                }
                data-testid="BtnPackingPrintDoc"
              >
                Print
              </S.PrimaryButton>
            </S.PrintInfoItem>
          </S.PrintInfoWrapper>
        </Grid>
        {packingTask.picking_task.po_type === OrderType.PRECURSOR && (
          <Grid item xs={12}>
            <S.PrintInfoWrapper container>
              <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
                <CheckCircle
                  sx={{
                    color: isPrinted[PrintPDFTypeEnum.ESP]
                      ? 'primary.main'
                      : 'primary.light',
                  }}
                  style={{ marginRight: 16 }}
                />
                <div>
                  <b>PRINT ESP</b> <br />
                </div>
              </S.PrintInfoItem>
              <S.PrintInfoItem item xs={3} sm={2} className="align-right">
                <S.PrimaryButton
                  onClick={() => printPackingTaskPDF(PrintPDFTypeEnum.ESP)}
                  data-testid="BtnPackingPrintESP"
                >
                  Print
                </S.PrimaryButton>
              </S.PrintInfoItem>
            </S.PrintInfoWrapper>
          </Grid>
        )}
        <Grid item xs={12}>
          <S.PrintInfoWrapper container>
            <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
              <CheckCircle
                sx={{
                  color: isPrinted[PrintPDFTypeEnum.SHIPPING_LABEL]
                    ? 'primary.main'
                    : 'primary.light',
                }}
                style={{ marginRight: 16 }}
              />
              <b>PRINT SHIPPING LABEL</b>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={3} sm={2} className="align-right">
              <S.PrimaryButton
                disabled={koli.length === 0}
                onClick={() =>
                  printPackingTaskPDF(PrintPDFTypeEnum.SHIPPING_LABEL)
                }
                data-testid="BtnPackingPrintSL"
              >
                Print
              </S.PrimaryButton>
            </S.PrintInfoItem>
            {/* input koli section */}
            <S.PrintInfoItem item xs={6} sm={4}>
              <p>Koli Number</p>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={6} sm={8} className="inline-flex">
              <TextField
                variant="outlined"
                placeholder="0"
                type="number"
                value={koli.length || ''}
                onChange={(e) => setKoliNumber(+e.target.value)}
                inputProps={{
                  sx: {
                    textAlign: 'right',
                    '&::placeholder': {
                      textAlign: 'right',
                    },
                  },
                  'data-testid': 'InputPackingKoli',
                }}
              />
            </S.PrintInfoItem>

            {/* Commented as currently no need to input weight for koli
                Might be needed in the future. Remove completely if the business
                flow is fixed to not use this section.
            */}
            {/* {koli.map((k, idx) => (
              <>
                <S.PrintInfoItem item xs={6} sm={4}>
                  <p>Koli {idx + 1}</p>
                </S.PrintInfoItem>
                <S.PrintInfoItem item xs={6} sm={8} className="inline-flex">
                  <TextField
                    key={`Koli ${idx + 1}`}
                    variant="outlined"
                    placeholder="0"
                    type="number"
                    value={k.weight || ''}
                    onChange={(e) => setKoliInfo(idx, e.target.value)}
                    inputProps={{
                      sx: {
                        textAlign: 'right',
                        '&::placeholder': {
                          textAlign: 'right',
                        },
                      },
                      min: 0,
                    }}
                  />
                  <span>kg</span>
                </S.PrintInfoItem>
              </>
            ))} */}
          </S.PrintInfoWrapper>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton
          disabled={
            !isPrinted[PrintPDFTypeEnum.INVOICE_TTF] ||
            !isPrinted[PrintPDFTypeEnum.SHIPPING_LABEL]
          }
          onClick={proceedToNextPage}
          data-testid="BtnPackingProceed"
        >
          PROCEED
        </S.PrimaryButton>
      </S.FooterWrapper>
      <S.BlankWhiteSpace />

      <StationNumberDialog />
    </div>
  );
};

export default PackPrintPage;
