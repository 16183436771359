import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add, Check, Clear, ErrorOutline } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useDebouncedCallback } from 'use-debounce';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import * as colors from 'commons/styles/colors';
import { PartialPickingTask } from 'commons/types';
import {
  PickingBySKUsDetails,
  PickingInfo,
  PickingListBySKUsUpdatePayload,
} from 'commons/types/pickingList.interface';
import {
  getRecommendation,
  getProductDetail,
  postPickedItem,
  assignBasket,
  getPickTaskBySKU,
  postRemoveBatch,
  postPickBasketVerification,
} from 'redux-stores/actions';
import { selectUser } from 'redux-stores/features/authSlice';
import { RootReducerInterface } from 'redux-stores/reducers';
import { updateTaskBySKUPickedActual } from 'redux-stores/reducers/pickingTaskReducer';
import { AppDispatch } from 'redux-stores/store';
import { IPickingJobPickedItemVerificationRequest } from 'services/apis/SwipeRxWmsApiPickJob/types';

import { AssignBasketModal } from '../commons/components/assign-basket-modal';
import { onRequestStocks } from '../commons/components/utils';

import S from './PickItemPage.style';
import { config } from './pickItemTable.config';

interface PickProcessState {
  isOpen: boolean;
  picked: PickingInfo[];
  poNumber: string;
}

const PickItemPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    state: { pickIdx },
  } = useLocation();
  const user = useSelector(selectUser);
  const { tasksBySKUs, skuList, skuLowStockTriggered } = useSelector(
    (state: RootReducerInterface) => state.pickingTask,
  );

  const [pickList, setPickList] = useState(tasksBySKUs[pickIdx]);
  const [controlIdx, setControlIdx] = useState(pickIdx);
  const [isAllBasketVerified, setIsAllBasketVerified] = useState(false);

  useEffect(() => {
    if (controlIdx !== pickIdx) {
      dispatch(getPickTaskBySKU());
      setControlIdx(pickIdx);
    } else {
      /* there's a chance for taskBySKUs be reordered, need to find the real current sku task */
      const currentTask = tasksBySKUs.find(
        (task) => task.sku_code === skuList[pickIdx],
      );
      /* fallback to tasksBySKUs index if current sku not found in skuList */
      const newPicklist = currentTask || tasksBySKUs[pickIdx];
      setPickList(newPicklist);
    }
  }, [controlIdx, dispatch, pickIdx, skuList, tasksBySKUs]);

  const baskets = useMemo(() => {
    return pickList?.details.flatMap((detail) => detail.baskets) || [];
  }, [pickList?.details]);

  useEffect(() => {
    dispatch(
      getRecommendation({
        skuCode: pickList?.sku_code,
        quantity: pickList?.total_quantity,
      }),
    );
  }, [dispatch, pickList?.sku_code, pickList?.total_quantity, baskets]);

  const defaultPickingInfo = (
    basket: number,
    poNumber: string,
  ): PickProcessState => ({
    poNumber,
    isOpen: true,
    picked: [
      {
        basket,
        bin: '',
        inventory_number: '',
        expiry_date: '',
        quantity: 0,
        is_verified: false,
      },
    ],
  });

  const [pickProcessState, setPickProcessState] = useState<
    Map<string, PickProcessState>
  >(new Map());
  const [pickedProgress, setPickedProgress] = useState(0);
  const [colorProgress, setColorProgress] = useState(colors.PRIMARY_RED);

  useMemo(() => {
    let quantity = 0;
    let _isAllBasketVerified = true;
    pickProcessState.forEach((pick) => {
      pick.picked.forEach((picked) => {
        quantity += picked.quantity;
        /* 
          check if all basket is verified. 
          if quantity is 0, treat it as verified 
          as it means the basket is not yet filled and no item is picked, 
          so no need to verify the basket yet 
        */
        _isAllBasketVerified =
          _isAllBasketVerified && (picked.is_verified || picked.quantity === 0);
      });
    });
    setPickedProgress(quantity);
    const color =
      quantity === 0
        ? colors.PRIMARY_RED
        : quantity === pickList?.total_quantity
        ? colors.PRIMARY_GREEN
        : colors.PRIMARY_YELLOW;
    setColorProgress(color);
    setIsAllBasketVerified(_isAllBasketVerified);
  }, [pickList?.total_quantity, pickProcessState]);

  const isFirstPage = useMemo(() => {
    return pickIdx <= 0;
  }, [pickIdx]);
  const isLastPage = useMemo(() => {
    return pickIdx >= tasksBySKUs.length - 1;
  }, [pickIdx, tasksBySKUs.length]);

  useEffect(() => {
    const initPickProcessState = (): Map<string, PickProcessState> => {
      const result = new Map();

      pickList?.details.forEach((detail) =>
        detail.baskets.forEach((basket) => {
          const sku = pickList.sku_code;
          const key = `${detail.po_number}-${sku}-${basket}`;
          // check existing data. if no data picked yet, use defaultPickingInfo
          const existingPickedData = detail.picked_actual.filter(
            (picked) => picked.basket === basket,
          );
          if (existingPickedData.length > 0) {
            result.set(key, {
              isOpen: true,
              picked: existingPickedData,
              quantity: detail.quantity,
              poNumber: detail.po_number,
            });
          } else {
            result.set(key, defaultPickingInfo(basket, detail.po_number));
          }
        }),
      );

      return result;
    };
    setPickProcessState(initPickProcessState());
  }, [pickList?.details, pickList?.sku_code]);

  const updateTaskBySKUs = (): void => {
    const payload: PickingListBySKUsUpdatePayload = {};

    Array.from(pickProcessState.values()).forEach((data) => {
      if (payload[data.poNumber]) {
        payload[data.poNumber].push(...data.picked);
      } else {
        payload[data.poNumber] = [...data.picked];
      }
    });

    // update redux state
    dispatch(updateTaskBySKUPickedActual(payload));
  };

  const goToPrevSKUHandler = (): void => {
    updateTaskBySKUs();
    if (isFirstPage) {
      navigate('/pick-task/list');
      return;
    }
    const newIdx = pickIdx - 1;
    navigate('/pick-task/item', { state: { pickIdx: newIdx } });
  };

  const goToNextSKUHandler = (): void => {
    updateTaskBySKUs();
    if (isLastPage) {
      navigate('/pick-task/list');
      return;
    }
    const newIdx = pickIdx + 1;
    navigate('/pick-task/item', { state: { pickIdx: newIdx } });
  };

  const [openAssignBasketModal, setOpenAssignBasketModal] =
    useState<boolean>(false);
  const [taskModal, setTaskModal] = useState<PartialPickingTask | null>(null);
  const onModalClose = (): void => setOpenAssignBasketModal(false);
  const onModalOpen = (detail: PickingBySKUsDetails): void => {
    setTaskModal({ baskets: detail.baskets, po_number: detail.po_number });
    setOpenAssignBasketModal(true);
  };
  const onAssignBasketConfirm = (task: PartialPickingTask): void => {
    const assignBasketDispatch = async (): Promise<void> => {
      /* sequential: assign basket, and then after that refresh the pick task with the updated basket */
      await dispatch(assignBasket({ task }));
      /* FIXME temporary fix for intermitten issue: add wait interval for better chance it get the updated basket */
      setTimeout(async () => {
        await dispatch(getPickTaskBySKU());
      }, 500);
    };

    assignBasketDispatch();
    setOpenAssignBasketModal(false);
  };

  const debouncedPickItem = useDebouncedCallback(
    (params: {
      binNumber: string;
      basket: number;
      expiryDate: Date;
      inventoryNumber: string;
      quantity: number;
      poNumber: string;
      skuCode: string;
    }) => {
      postPickedItem(params, dispatch);
    },
    500,
    {
      maxWait: 2000,
    },
  );

  const debouncedPickItemVerification = useDebouncedCallback(
    async (
      verificationParams: IPickingJobPickedItemVerificationRequest,
      params: {
        state: PickProcessState;
        pickIndex: number;
        key: string;
      },
    ): Promise<void> => {
      const { state, pickIndex, key } = params;
      const result = await postPickBasketVerification(
        verificationParams,
        dispatch,
      );
      const clonedPicked = structuredClone(state.picked);
      clonedPicked[pickIndex].is_verified = !!result;
      clonedPicked[pickIndex].basket_verification = undefined;
      setPickProcessState(
        new Map(pickProcessState.set(key, { ...state, picked: clonedPicked })),
      );
    },
    1000,
    { maxWait: 2000 },
  );

  const debouncedProductDetail = useDebouncedCallback(
    async (params: {
      binNumber: string;
      skuCode: string;
      inventoryNumber: string;
      state: PickProcessState;
      pickIndex: number;
      key: string;
    }) => {
      const { state, pickIndex, key, ...restParams } = params;
      const result = await getProductDetail(restParams, dispatch);
      if (result) {
        const clonedPicked = structuredClone(state.picked);
        clonedPicked[pickIndex].expiry_date = result.expiry_date.toString();
        clonedPicked[pickIndex].bin = result.bin;
        clonedPicked[pickIndex].inventory_number = result.inventory_number;
        setPickProcessState(
          new Map(
            pickProcessState.set(key, { ...state, picked: clonedPicked }),
          ),
        );
      }
    },
    500,
    { maxWait: 2000, trailing: true },
  );
  const debouncedRemoveBatch = useDebouncedCallback(
    (params: {
      binNumber: string;
      inventoryNumber: string;
      poNumber: string;
      skuCode: string;
      basket: number;
    }) => {
      postRemoveBatch(params);
    },
    500,
    {
      maxWait: 2000,
    },
  );

  const debouncedFocusNextField = useDebouncedCallback((selectorId: string) => {
    const nextInput = document.querySelector<HTMLInputElement>(selectorId);
    if (nextInput) nextInput.focus();
  }, 500);

  // early return process if pickList is null
  // to handle case page being refreshed
  if (!pickList) {
    navigate('/pick-task/list');
    return <div />;
  }

  const recommendationTotalQty = pickList.pick_recommendation.reduce(
    (total, rec) => total + rec.quantity,
    0,
  );
  const isStockOut =
    !skuLowStockTriggered[pickList.sku_code] &&
    recommendationTotalQty < pickList.total_quantity;

  const renderPickProcessForm = (
    state: PickProcessState,
    key: string,
    maxQuantity: number = Number.POSITIVE_INFINITY,
  ): React.JSX.Element[] => {
    return state.picked.map((info, idx) => {
      const replaceBatch = (
        index: number,
        pickingInfoList: PickingInfo[],
        newPickingInfo: PickingInfo,
      ): PickingInfo[] => {
        if (index < 0 && index >= pickingInfoList.length) {
          return state.picked;
        }
        const updatedPicked = structuredClone(pickingInfoList);
        const replaced = updatedPicked.splice(index, 1, newPickingInfo);
        if (replaced) {
          const { poNumber } = state;
          replaced.forEach((item) => {
            const {
              bin: binNumber,
              inventory_number: inventoryNumber,
              basket,
            } = item;
            debouncedRemoveBatch({
              binNumber,
              inventoryNumber,
              poNumber,
              skuCode: pickList.sku_code,
              basket,
            });
          });
        }

        return updatedPicked;
      };

      const onFieldChange = (
        type: keyof PickingInfo,
        value: string,
        nextField?: string,
      ): void => {
        let clonedPicked = structuredClone(state.picked);
        const prevValue = clonedPicked[idx][type];
        const cleanNewValue = value.trim();

        if (
          (type === 'bin' || type === 'inventory_number') &&
          clonedPicked[idx].quantity !== 0
        ) {
          const newBatch: PickingInfo = {
            bin: '',
            inventory_number: '',
            expiry_date: '',
            quantity: 0,
            basket: clonedPicked[idx].basket,
            is_verified: false,
            basket_verification: 0,
          };
          newBatch[type] = cleanNewValue;
          clonedPicked = replaceBatch(idx, clonedPicked, newBatch);
        } else if (
          /*
          data type number
          need to be in this chained OR format, so the else block could executed properly 
          */
          type === 'quantity' ||
          type === 'basket' ||
          type === 'basket_verification'
        ) {
          // condition for tally scanning; read qr_code to match sku_code
          // DEPRECATED: remove tally scanning. Keep code for some time until the feature is really not needed in the future
          // if (cleanNewValue.includes(pickList.sku_code)) {
          //   if (clonedPicked[idx].quantity + 1 <= maxQuantity)
          //     clonedPicked[idx].quantity += 1;
          // } else if (!Number.isNaN(+cleanNewValue)) {
          if (!Number.isNaN(+cleanNewValue)) {
            if (type === 'quantity') {
              const fieldQuantity =
                cleanNewValue && +cleanNewValue > 0 ? +cleanNewValue : 0;
              clonedPicked[idx].quantity =
                fieldQuantity > maxQuantity ? maxQuantity : fieldQuantity;
              clonedPicked[idx].basket_verification = 0;
            } else if (type === 'basket_verification') {
              if (+cleanNewValue <= 0) {
                /* ignore invalid value. skip */
                return;
              }
              clonedPicked[idx].basket_verification = +cleanNewValue;
            }
          }
        } else if (type === 'is_verified') {
          // should not be inputted by user, ignore
          return;
        } else {
          clonedPicked[idx][type] = cleanNewValue;
          clonedPicked[idx].basket_verification = 0;
        }
        clonedPicked[idx].is_verified = false;

        const newPickProcessState = structuredClone(pickProcessState);
        newPickProcessState.set(key, { ...state, picked: clonedPicked });
        setPickProcessState(newPickProcessState);

        /* any edit, all basket must be re-verified */
        setIsAllBasketVerified(false);

        /* debounce after state assignment to prevent race condition */
        const {
          bin,
          expiry_date: expiryDate,
          inventory_number: inventoryNumber,
          quantity,
          basket,
          basket_verification: basketVerification,
        } = clonedPicked[idx];
        if (
          (type === 'bin' || type === 'inventory_number') &&
          bin &&
          inventoryNumber
        ) {
          debouncedProductDetail({
            binNumber: bin,
            inventoryNumber,
            skuCode: pickList.sku_code,
            key,
            pickIndex: idx,
            state,
          });
        }
        if (type === 'basket_verification' && basketVerification) {
          debouncedPickItemVerification(
            {
              binNumber: bin,
              basket,
              expiryDate: new Date(expiryDate),
              inventoryNumber,
              poNumber: state.poNumber,
              quantity,
              skuCode: pickList.sku_code,
              basketVerify: basketVerification,
            },
            { key, pickIndex: idx, state },
          );
        } else if (bin && inventoryNumber && basket && quantity) {
          debouncedPickItem({
            binNumber: bin,
            basket,
            expiryDate: new Date(expiryDate),
            inventoryNumber,
            poNumber: state.poNumber,
            quantity,
            skuCode: pickList.sku_code,
          });
        } else if (quantity === 0) {
          replaceBatch(idx, clonedPicked, clonedPicked[idx]);
        }

        if (
          nextField &&
          (value.endsWith('\t') ||
            value.endsWith('\n') ||
            (!prevValue && value.length > 1))
        ) {
          debouncedFocusNextField(
            `input[id="field-pick-${nextField}-${basket}-${idx}"]`,
          );
        }
      };

      const clearField = (field: keyof PickingInfo) => {
        const clonedPicked = structuredClone(state.picked);
        if (
          field === 'bin' ||
          field === 'inventory_number' ||
          field === 'expiry_date'
        ) {
          clonedPicked[idx][field] = '';
        } else if (field === 'is_verified') {
          clonedPicked[idx][field] = false;
        } else {
          clonedPicked[idx][field] = 0;
        }

        const newPickProcessState = structuredClone(pickProcessState);
        newPickProcessState.set(key, { ...state, picked: clonedPicked });
        setPickProcessState(newPickProcessState);
      };

      return (
        <Grid container style={{ padding: '8px 0' }}>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <Grid container>
              <Grid item xs={5}>
                <p>Bin</p>
              </Grid>
              <S.PickProcessInputGrid item xs={6}>
                <TextField
                  variant="outlined"
                  placeholder="-"
                  value={info.bin}
                  disabled={isStockOut}
                  onChange={(event) =>
                    onFieldChange('bin', event.target.value, 'batch')
                  }
                  id={`field-pick-bin-${info.basket}-${idx}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => clearField('bin')}>
                          <Clear style={{ color: colors.PRIMARY_GRAY }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { paddingRight: 0 },
                  }}
                />
              </S.PickProcessInputGrid>
              <Grid item xs={5}>
                <p>Batch Number</p>
              </Grid>
              <S.PickProcessInputGrid item xs={6}>
                <TextField
                  variant="outlined"
                  placeholder="-"
                  value={info.inventory_number}
                  disabled={isStockOut}
                  onChange={(event) =>
                    onFieldChange(
                      'inventory_number',
                      event.target.value,
                      'quantity',
                    )
                  }
                  id={`field-pick-batch-${info.basket}-${idx}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => clearField('inventory_number')}
                        >
                          <Clear style={{ color: colors.PRIMARY_GRAY }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { paddingRight: 0 },
                  }}
                />
              </S.PickProcessInputGrid>
              <Grid item xs={5}>
                <p>Expiry Date</p>
              </Grid>
              <S.PickProcessInputGrid item xs={6}>
                <DateField
                  variant="outlined"
                  defaultValue={null}
                  value={
                    info.expiry_date ? DateTime.fromISO(info.expiry_date) : null
                  }
                  disabled={isStockOut}
                  onChange={(value) =>
                    onFieldChange(
                      'expiry_date',
                      value?.toFormat('dd/LL/yyyy') || '',
                    )
                  }
                  id={`field-pick-expiry-${info.basket}-${idx}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => clearField('expiry_date')}>
                          <Clear style={{ color: colors.PRIMARY_GRAY }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { paddingRight: 0 },
                  }}
                />
              </S.PickProcessInputGrid>
              <Grid item xs={5}>
                <p>Quantity Picked</p>
              </Grid>
              <S.PickProcessInputGrid item xs={6}>
                <TextField
                  variant="outlined"
                  placeholder="0"
                  disabled={isStockOut}
                  type="number"
                  value={info.quantity || ''}
                  onChange={(event) =>
                    onFieldChange('quantity', event.target.value)
                  }
                  id={`field-pick-quantity-${info.basket}-${idx}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => onFieldChange('quantity', '0')}
                        >
                          <Clear style={{ color: colors.PRIMARY_GRAY }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { paddingRight: 0 },
                  }}
                />
              </S.PickProcessInputGrid>
              <Grid item xs={5}>
                <p>Basket Verification</p>
              </Grid>
              <S.PickProcessInputGrid item xs={6}>
                <TextField
                  variant="outlined"
                  placeholder="0"
                  type="number"
                  value={info.basket_verification || ''}
                  onChange={(event) => {
                    onFieldChange('basket_verification', event.target.value);
                  }}
                  id={`field-pick-basket-verification-${info.basket}-${idx}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            onFieldChange('basket_verification', '0');
                          }}
                        >
                          {info.is_verified ? (
                            <Check style={{ color: colors.PRIMARY_GRAY }} />
                          ) : info.quantity === 0 ? (
                            <Clear style={{ color: colors.PRIMARY_GRAY }} />
                          ) : (
                            <ErrorOutline
                              style={{ color: colors.PRIMARY_RED }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { paddingRight: 0 },
                  }}
                />
              </S.PickProcessInputGrid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const renderPickProcessRow = (
    skuCode: string,
    detail: PickingBySKUsDetails,
  ): React.JSX.Element[] => {
    const basketProgress = detail.baskets.reduce((sum, basketNumber) => {
      const sku = pickList.sku_code;
      const key = `${detail.po_number}-${sku}-${basketNumber}`;
      const state = pickProcessState.get(key);
      if (!state) return sum;
      return state.picked.reduce((sum1, pick) => pick.quantity + sum1, 0) + sum;
    }, 0);

    return detail.baskets.map((basketNumber, idx) => {
      const sku = pickList.sku_code;
      const key = `${detail.po_number}-${sku}-${basketNumber}`;
      const state = pickProcessState.get(key);
      if (!state) return <div />;

      const maxQuantityToAdd =
        detail.quantity -
        detail.baskets
          .filter((basketCheck) => basketCheck !== basketNumber)
          .reduce((sum, num) => {
            const _key = `${detail.po_number}-${num}`;
            const _state = pickProcessState.get(_key);
            if (!_state) return sum;
            return (
              _state.picked.reduce((sum1, pick) => pick.quantity + sum1, 0) +
              sum
            );
          }, 0);

      const onContainerClicked = (): void => {
        const clonedMap = new Map(
          pickProcessState.set(key, { ...state, isOpen: !state.isOpen }),
        );
        setPickProcessState(clonedMap);
      };

      const addBatch = (): void => {
        const updatedPicked = [
          ...state.picked,
          {
            basket: basketNumber,
            bin: '',
            inventory_number: '',
            expiry_date: '',
            quantity: 0,
            is_verified: false,
          },
        ];

        setPickProcessState(
          new Map(
            pickProcessState.set(key, { ...state, picked: updatedPicked }),
          ),
        );
      };

      const removeBatch = (): void => {
        const pickingInfo = state.picked.pop();
        if (pickingInfo) {
          const { poNumber } = state;
          const {
            bin,
            inventory_number: inventoryNumber,
            basket,
          } = pickingInfo;
          postRemoveBatch({
            binNumber: bin,
            inventoryNumber,
            poNumber,
            skuCode,
            basket,
          });
        }

        setPickProcessState(new Map(pickProcessState.set(key, { ...state })));
      };

      return (
        <S.PickProcessWrapper>
          <S.PickProcessGridContainer container onClick={onContainerClicked}>
            <Grid item xs={1}>
              <S.PickProcessArrow className={state.isOpen ? 'open' : 'close'} />
            </Grid>
            <S.PickProcessTitleText item xs={11}>
              <Grid container>
                <Grid item xs={idx === 0 ? 6 : 12}>
                  <p data-testid={`TextItemBasket-${idx + 1}`}>
                    Basket {basketNumber}
                  </p>
                </Grid>
                {idx === 0 && (
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <p>
                      QTY: {basketProgress}/{detail.quantity}
                    </p>
                  </Grid>
                )}
                <Grid xs={6}>
                  <p data-testid={`TextItemPO-${idx + 1}`}>
                    {detail.po_number}
                  </p>
                </Grid>
                <Grid xs={6} style={{ textAlign: 'right' }}>
                  <p>{detail.priority}</p>
                </Grid>
              </Grid>
            </S.PickProcessTitleText>
          </S.PickProcessGridContainer>

          {state.isOpen && renderPickProcessForm(state, key, maxQuantityToAdd)}

          {state.isOpen && (
            <Grid container style={{ marginTop: 8 }}>
              <Grid item xs={6} style={{ textAlign: 'center' }}>
                <S.RedButtonText
                  onClick={() => removeBatch()}
                  disabled={state.picked.length === 1}
                  data-testid={`BtnItemRmvBatch-${idx + 1}`}
                >
                  REMOVE BATCH
                </S.RedButtonText>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <S.BlueButtonText
                  disabled={isStockOut}
                  onClick={addBatch}
                  data-testid={`BtnItemAddBatch-${idx + 1}`}
                >
                  <Add /> ADD BATCH
                </S.BlueButtonText>
              </Grid>
            </Grid>
          )}
        </S.PickProcessWrapper>
      );
    });
  };

  const renderPickingProcess = (): React.JSX.Element[] => {
    return pickList?.details.map((detail, idx) => (
      <div>
        {renderPickProcessRow(pickList.sku_code, detail)}
        {/* Add Basket Button */}
        <S.AddBasketButtonWrapper>
          <S.SecondaryButton
            disabled={isStockOut}
            onClick={() => onModalOpen(detail)}
            data-testid={`BtnItemAddBasket-${idx + 1}`}
          >
            ADD ANOTHER BASKET
          </S.SecondaryButton>
        </S.AddBasketButtonWrapper>
      </div>
    ));
  };

  // early return if no taskBySKU found in state
  if (tasksBySKUs.length === 0) {
    navigate('/pick-task/list');
    return <div />;
  }
  return (
    <div>
      <Header
        title="Pick Task"
        prevPageHandler={() => {
          navigate('/pick-task/list');
        }}
      />
      {/* Content Section */}
      <S.ContentWrapper>
        <S.SubtitleWrapper container>
          <S.Subtitle item xs={12} data-testid="TextItemSKU">
            {pickList?.sku_code}
          </S.Subtitle>
          <S.Subtitle item xs={12} data-testid="TextItemProduct">
            {pickList?.sku_name}
          </S.Subtitle>
          <S.UOMSubtitle item xs={12} data-testid="TextItemUom">
            {pickList?.uom}
          </S.UOMSubtitle>
        </S.SubtitleWrapper>
        <S.HeaderContentWrapper container>
          <Grid item xs={6}>
            <S.ProgressText data-testid="TextItemOrderQty">
              TOTAL ORDER QTY: {pickList?.total_quantity}
            </S.ProgressText>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <S.ProgressText>
              ORDER PICKED:
              <S.ProgressSpan
                color={colorProgress}
                data-testid="TextItemOrderPicked"
              >
                {pickedProgress}/{pickList?.total_quantity}
              </S.ProgressSpan>
            </S.ProgressText>
          </Grid>
        </S.HeaderContentWrapper>
        <S.HeaderTableWrapper>
          <TableComponent
            data={pickList?.pick_recommendation}
            config={config}
            additionalTableConfig={{ bodyFontSize: '12px' }}
          />
        </S.HeaderTableWrapper>
        {isStockOut && (
          <S.HeaderReplenishContentWrapper>
            <S.HeaderReplenishGridContainer container>
              <S.HeaderReplenishGrid item xs={6}>
                Out of stock.
              </S.HeaderReplenishGrid>
              <S.HeaderReplenishGrid style={{ alignItems: 'end' }} item xs={6}>
                <S.BlueButtonTextNoBG
                  style={{
                    padding: 0,
                    float: 'right',
                  }}
                  onClick={() =>
                    onRequestStocks(pickList, user.warehouseId, dispatch)
                  }
                >
                  REQUEST STOCKS
                </S.BlueButtonTextNoBG>
              </S.HeaderReplenishGrid>
            </S.HeaderReplenishGridContainer>
          </S.HeaderReplenishContentWrapper>
        )}

        {renderPickingProcess()}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton
              onClick={goToPrevSKUHandler}
              disabled={!isAllBasketVerified}
              data-testid="BtnItemPrev"
            >
              {isFirstPage ? 'BACK TO PICK LIST' : 'PREV SKU'}
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton
              onClick={goToNextSKUHandler}
              disabled={!isAllBasketVerified}
              data-testid="BtnNext"
            >
              {isLastPage ? 'DONE' : 'NEXT SKU'}{' '}
              {isAllBasketVerified ? '✔' : '✘'}
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>

      {/* Modal */}
      {openAssignBasketModal && (
        <AssignBasketModal
          task={taskModal}
          onClose={onModalClose}
          openModal={openAssignBasketModal}
          onConfirm={onAssignBasketConfirm}
          removeDisabled
          disableExisting
        />
      )}
    </div>
  );
};

export default PickItemPage;
