import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { PRIMARY_RED } from 'commons/styles/colors';
import { APJInfo, APJList, ICompanyInfo, OrderType } from 'commons/types';
import {
  getCompanyInfo,
  updateCompanyInfo,
  uploadApjSignature,
} from 'redux-stores/actions/companyInfo.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import { ListPage } from '../commons/components';

import S from './CompanyInfo.styles';

const CompanyInfoPage: React.FC = () => {
  const { company } = useSelector(
    (state: RootReducerInterface) => state.company,
  );

  const { selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const [companyInfoState, setCompanyInfoState] = useState<ICompanyInfo | null>(
    company,
  );

  const dispatch = useDispatch<AppDispatch>();

  const updateCompanyInfoSearchDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getCompanyInfo({
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  useEffect(() => {
    setCompanyInfoState(company);
  }, [company, dispatch]);

  const onFieldChange = (key: keyof ICompanyInfo, value: string) => {
    if (companyInfoState) {
      setCompanyInfoState({
        ...companyInfoState,
        [key]: value,
      });
    }
  };

  const onFieldChangeAPJ = (
    apjType: keyof APJList,
    apjKey: keyof APJInfo,
    value: string,
  ) => {
    if (companyInfoState) {
      setCompanyInfoState({
        ...companyInfoState,
        apj: {
          ...companyInfoState.apj,
          [apjType]: {
            ...companyInfoState.apj[apjType],
            [apjKey]: value,
          },
        },
      });
    }
  };

  const handleUploadPhoto = async (
    event: React.ChangeEvent<HTMLInputElement>,
    apjType: OrderType,
  ): Promise<void> => {
    if (companyInfoState) {
      const file = event.target.files?.[0];
      if (file) {
        if (file.type === 'image/svg+xml') {
          await dispatch(
            uploadApjSignature({
              locationId: selectedLocation?.warehouse_id,
              apjType,
              file,
            }),
          );
        } else {
          dispatch(
            snackbarSetData({
              open: true,
              message: 'File type not allowed',
              color: PRIMARY_RED,
            }),
          );
        }
      }
    }
  };

  const handleSave = () => {
    if (companyInfoState) {
      dispatch(updateCompanyInfo(companyInfoState));
    }
  };

  return (
    <ListPage updateListSearchDebounce={updateCompanyInfoSearchDebounce}>
      <Card style={{ overflow: 'visible' }}>
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <S.InfoWrapper container style={{ flexDirection: 'column' }}>
            <S.Title>Company Info</S.Title>
            <S.Subtitle>Basic Information</S.Subtitle>
            <Grid item xs={12}>
              <S.Label>Name</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.name || ''}
                id={`field-company-info-name-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) => onFieldChange('name', event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Address</S.Label>
              <S.TextArea
                minRows={4}
                maxRows={6}
                placeholder="-"
                value={companyInfoState?.address || ''}
                id={`field-company-info-address-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChange('address', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Pbf Number</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.pbf_no || ''}
                id={`field-company-info-pbf-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChange('pbf_no', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Dak Number</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.dak_no || ''}
                id={`field-company-info-dak-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChange('dak_no', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Cdob Number</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.cdob_no || ''}
                id={`field-company-info-cdob-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChange('cdob_no', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>VA BCA</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.va_bca || ''}
                id={`field-company-info-bca-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChange('va_bca', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>VA Mandiri</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.va_mandiri || ''}
                id={`field-company-info-mandiri-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChange('va_mandiri', event.target.value)
                }
              />
            </Grid>
            <S.Subtitle>APJ Regular</S.Subtitle>
            <Grid item xs={12}>
              <S.Label>Name</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.apj.regular.name || ''}
                id={`field-company-info-apj-regular-name-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChangeAPJ(
                    OrderType.REGULAR,
                    'name',
                    event.target.value,
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Sipa</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.apj.regular.sipa || ''}
                id={`field-company-info-apj-regular-sipa-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChangeAPJ(
                    OrderType.REGULAR,
                    'sipa',
                    event.target.value,
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Signature</S.Label>
              <S.ApjSignatureWrapper>
                <img
                  src={`${companyInfoState?.apj.regular
                    .signature}?${new Date()}`}
                  alt="apj regular signature"
                  style={{ width: '100%' }}
                />
              </S.ApjSignatureWrapper>
              <div>
                <Button variant="outlined">
                  <label htmlFor="regular-sign-input">
                    <input
                      id="regular-sign-input"
                      type="file"
                      hidden
                      onChange={(e) => handleUploadPhoto(e, OrderType.REGULAR)}
                      accept=".svg"
                    />
                    Upload
                  </label>
                </Button>
              </div>
            </Grid>
            <S.Subtitle>APJ Precursor</S.Subtitle>
            <Grid item xs={12}>
              <S.Label>Name</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.apj.precursor.name || ''}
                id={`field-company-info-apj-precursor-name-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChangeAPJ(
                    OrderType.PRECURSOR,
                    'name',
                    event.target.value,
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Sipa</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.apj.precursor.sipa || ''}
                id={`field-company-info-apj-precursor-sipa-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChangeAPJ(
                    OrderType.PRECURSOR,
                    'sipa',
                    event.target.value,
                  )
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Signature</S.Label>
              <S.ApjSignatureWrapper>
                <img
                  src={`${companyInfoState?.apj.precursor
                    .signature}?${new Date()}`}
                  alt="apj precursor signature"
                  style={{ width: '100%' }}
                />
              </S.ApjSignatureWrapper>
              <div>
                <Button variant="outlined">
                  <label htmlFor="precursor-sign-input">
                    <input
                      id="precursor-sign-input"
                      type="file"
                      hidden
                      onChange={(e) =>
                        handleUploadPhoto(e, OrderType.PRECURSOR)
                      }
                      accept=".svg"
                    />
                    Upload
                  </label>
                </Button>
              </div>
            </Grid>
            <S.Subtitle>APJ Alkes</S.Subtitle>
            <Grid item xs={12}>
              <S.Label>Name</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.apj.alkes.name || ''}
                id={`field-company-info-apj-alkes-name-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChangeAPJ(OrderType.ALKES, 'name', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Sipa</S.Label>
              <TextField
                variant="outlined"
                placeholder="-"
                value={companyInfoState?.apj.alkes.sipa || ''}
                id={`field-company-info-apj-alkes-sipa-${companyInfoState?.name}`}
                style={{ width: '100%' }}
                onChange={(event) =>
                  onFieldChangeAPJ(OrderType.ALKES, 'sipa', event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <S.Label>Signature</S.Label>
              <S.ApjSignatureWrapper>
                <img
                  src={`${companyInfoState?.apj.alkes.signature}?${new Date()}`}
                  alt="apj alkes signature"
                  style={{ width: '100%' }}
                />
              </S.ApjSignatureWrapper>
              <div>
                <Button variant="outlined">
                  <label htmlFor="alkes-sign-input">
                    <input
                      id="alkes-sign-input"
                      type="file"
                      hidden
                      onChange={(e) => handleUploadPhoto(e, OrderType.ALKES)}
                      accept=".svg"
                    />
                    Upload
                  </label>
                </Button>
              </div>
            </Grid>
          </S.InfoWrapper>
          <Button
            variant="contained"
            onClick={handleSave}
            style={{
              width: '400px',
              marginTop: '24px',
            }}
          >
            <Typography variant="button">Save</Typography>
          </Button>
        </CardContent>
      </Card>
    </ListPage>
  );
};

export default CompanyInfoPage;
