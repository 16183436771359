import { User } from 'commons/types/user.interface';
import { UserRolesEnum } from 'commons/types/user.roles.enum';

// to determine default root url
export const getRootUrl = (user: User): string => {
  switch (user.roles.length) {
    case 1:
      switch (user.roles[0]) {
        case UserRolesEnum.PICKER:
        case UserRolesEnum.PICKER_LEAD:
        case UserRolesEnum.PICKER_SPV:
          return '/pick-task/assign';
        case UserRolesEnum.PACKER:
        case UserRolesEnum.PACKER_LEAD:
        case UserRolesEnum.PACKER_SPV:
          return '/pack-task/check-basket';
        case UserRolesEnum.ADMIN:
        case UserRolesEnum.SUPER_ADMIN:
          return '/home';
        case UserRolesEnum.REPLENISH:
          return '/home/inbound';
        default:
          return '/not-found';
      }
    case 0:
      return '/not-found';
    default:
      return '/home';
  }
};
