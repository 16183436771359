import { NavigateFunction } from 'react-router-dom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { BinType } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import {
  IStockTransfer,
  IStockTransferItemDestination,
  IStockTransferItemProps,
} from 'commons/types';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { RootReducerInterface } from 'redux-stores/reducers';
import {
  setUpdateStockTransfer,
  setStockTransfers,
  removeStockTransfer,
  setBinRecommendationInfo,
} from 'redux-stores/reducers/stockTransfer.reducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';
import { swipeRxWmsApiStockTransfer } from 'services/apis/SwipeRxWmsApiStockTransfer';

export const createNewStockTransfer = createAsyncThunk(
  'stockTransfer/createNewStockTransfer',
  async (params: { skuCode: string }, { dispatch, getState }) => {
    const { skuCode } = params;
    const state: RootReducerInterface = getState() as RootReducerInterface;
    const { stockTransfer } = state;
    const currentStockTransfer: IStockTransfer | undefined =
      stockTransfer.stockTransfer[skuCode];
    defaultActionProcess(async () => {
      if (!currentStockTransfer) {
        const newData = await swipeRxWmsApiStockTransfer.addNewStockTransfer({
          sku_code: skuCode,
        });
        dispatch(setUpdateStockTransfer(newData));
      }
    }, dispatch);
    return undefined;
  },
);

export const getStockTransferAction = createAsyncThunk(
  'stockTransfer/getStockTransferAction',
  async (_, { dispatch }) => {
    defaultActionProcess(async () => {
      const data = await swipeRxWmsApiStockTransfer.getStockTransfers();
      dispatch(setStockTransfers(data));
    }, dispatch);
  },
);

export const getStockTransferBySkuCodeAction = createAsyncThunk(
  'stockTransfer/getStockTransferBySkuCodeAction',
  async (
    params: {
      skuCode: string;
      updateSourceRecommendation?: boolean;
      navigate: NavigateFunction;
    },
    { dispatch },
  ) => {
    try {
      const data = await swipeRxWmsApiStockTransfer.getStockTransferBySkuCode({
        sku_code: params.skuCode,
        update_source_recommendation: params.updateSourceRecommendation,
      });
      dispatch(setUpdateStockTransfer(data));
    } catch (error: any) {
      params.navigate('/stock-transfer/list/source');
      dispatch(
        snackbarSetData({
          open: true,
          message: error.message,
          color: PRIMARY_RED,
        }),
      );
    }
  },
);

export const getStockTransferProductDetail = async (
  params: {
    skuCode: string;
    bin: string;
    inventoryNumber: string;
  },
  dispatch: AppDispatch,
): Promise<IStockTransferItemProps | undefined> => {
  const { skuCode, bin, inventoryNumber } = params;
  const result = await defaultActionProcess(
    async () => {
      const response = await swipeRxWmsApiStockTransfer.getProductDetail(
        skuCode,
        bin,
        inventoryNumber,
      );
      return response;
    },
    dispatch,
    false,
  );
  return result;
};

export const getBinTypeFromBinName = async (
  params: {
    skuCode: string;
    bin: string;
  },
  dispatch: AppDispatch,
): Promise<string | undefined> => {
  const { skuCode, bin } = params;
  const result = await defaultActionProcess(
    async () => {
      const response = await swipeRxWmsApiStockTransfer.getBinTypeFromBinName(
        skuCode,
        bin,
      );
      return response;
    },
    dispatch,
    false,
  );
  return result;
};

export const postStockTransferPickSourceBin = async (
  params: { skuCode: string; sourceBin: IStockTransferItemProps },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, sourceBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiStockTransfer.postPickSourceBin(skuCode, sourceBin);
    },
    dispatch,
    false,
  );
};

export const postStockTransferRemoveSourceBatch = async (
  params: {
    skuCode: string;
    removedBin: Pick<IStockTransferItemProps, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, removedBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiStockTransfer.postRemoveSourceBatch(
        skuCode,
        removedBin,
      );
    },
    dispatch,
    false,
  );
};

export const postStockTransferPutDestinationBin = async (
  params: { skuCode: string; destinationBin: IStockTransferItemDestination },
  dispatch: AppDispatch,
): Promise<void> => {
  const { skuCode, destinationBin } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiStockTransfer.postPutDestinationBin(
        skuCode,
        destinationBin,
      );
    },
    dispatch,
    false,
  );
};

export const postRemoveStockTransferDestinationBatch = async (
  params: {
    skuCode: string;
    removedBin: Pick<IStockTransferItemDestination, 'bin' | 'inventory_number'>;
  },
  dispatch: AppDispatch,
): Promise<void> => {
  const { removedBin, skuCode } = params;
  defaultActionProcess(
    async () => {
      await swipeRxWmsApiStockTransfer.postRemoveStockTransferDestinationBatch(
        skuCode,
        removedBin,
      );
    },
    dispatch,
    false,
  );
};

export const cancelStockTransferAction = createAsyncThunk(
  'stockTransfer/cancelStockTransferAction',
  async (params: { transfer_number: string }, { dispatch }) => {
    defaultActionProcess(async () => {
      const data =
        await swipeRxWmsApiStockTransfer.cancelStockTransfers(params);
      dispatch(removeStockTransfer(data));
    }, dispatch);
  },
);

export const completeStockTransferAction = createAsyncThunk(
  'stockTransfer/completeStockTransferAction',
  async (
    params: { transfer_numbers: string[]; postAction: () => void },
    { dispatch },
  ) => {
    const { transfer_numbers: transferNumbers, postAction } = params;
    defaultActionProcess(async () => {
      await swipeRxWmsApiStockTransfer.completeStockTransfers({
        transfer_numbers: transferNumbers,
      });
      postAction();
    }, dispatch);
  },
);

export const getBinRecommendationInfo = createAsyncThunk(
  'stockTransfer/getBinRecommendationInfo',
  async (params: { skuCode: string; binType: BinType }, { dispatch }) => {
    const { skuCode, binType } = params;
    defaultActionProcess(async () => {
      const result = await swipeRxWmsApiStockTransfer.getBinRecommendationInfo(
        skuCode,
        binType,
      );

      dispatch(setBinRecommendationInfo(result));
    }, dispatch);
  },
);
