import React, { useState } from 'react';

import { skuSearchStyle as S } from './sku-search.style';

interface SkuSearchProps {
  onAddClicked: (sku: string) => void;
}

export const SkuSearch: React.FC<SkuSearchProps> = ({ onAddClicked }) => {
  const [skuSearch, setSkuSearch] = useState('');

  const onSearchChange = (value: string): void => {
    setSkuSearch(value);
  };

  return (
    <S.ContentWrapper>
      <S.SearchBar
        placeholder="SKU Number or Scan"
        variant="outlined"
        value={skuSearch}
        onChange={(e) => {
          onSearchChange(e.target.value);
        }}
        inputProps={{
          'data-testid': 'InputSTListSourceSKU',
        }}
      />
      <S.AddButton
        onClick={() => {
          onAddClicked(skuSearch.trim());
        }}
        disabled={!skuSearch}
        data-testid="BtnSTListSourceAdd"
      >
        Add
      </S.AddButton>
    </S.ContentWrapper>
  );
};
