import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Button } from '@mui/material';
import { FileRejection } from 'react-dropzone/.';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { Uploader } from 'commons/components/Uploader';
import { FileImportType } from 'commons/enums';
import { PRIMARY_RED } from 'commons/styles/colors';
import { bulkUpload } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { setUpdateListPage } from 'redux-stores/reducers/dashboard.reducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import { FileImportHistoryTable } from './FileImportHistory.component';

export interface FileImportTabProps {
  onDownloadTemplateClick: () => void;
  downloadTemplateBtnText: string;
  uploadType: FileImportType;
}

export const FileImportTab: React.FC<FileImportTabProps> = (
  props: FileImportTabProps,
) => {
  const { onDownloadTemplateClick, downloadTemplateBtnText, uploadType } =
    props;

  const dispatch = useDispatch<AppDispatch>();
  const { updateQueryParams } = useUpdateQueryParams();

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { selectedLocation, bulkUploadHistory } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const onUploadClicked = async ([file]: File[]): Promise<void> => {
    setIsUploading(true);
    dispatch(
      bulkUpload({
        file,
        fileImportType: uploadType,
        location: selectedLocation?.warehouse_id,
      }),
    ).then(() => {
      setIsUploading(false);
      dispatch(setUpdateListPage());
    });
  };

  const onFileRejected = async ([
    fileRejection,
  ]: FileRejection[]): Promise<void> => {
    dispatch(
      snackbarSetData({
        color: PRIMARY_RED,
        open: true,
        message: `"${fileRejection.file.name}" is not a CSV`,
      }),
    );
  };

  useEffect(() => {
    updateQueryParams(
      { file_import_type: uploadType },
      { clearQueryParams: ['page'] },
    );
  }, [uploadType]);

  return (
    <>
      <Typography variant="body2">
        Download CSV template &nbsp;
        <Button onClick={onDownloadTemplateClick}>
          {downloadTemplateBtnText}
        </Button>
      </Typography>
      <Uploader
        title="Drag your CSV file here"
        multiple={false}
        disabled={isUploading}
        onUpload={onUploadClicked}
        onDropRejected={onFileRejected}
      />
      <FileImportHistoryTable data={bulkUploadHistory} />
    </>
  );
};
