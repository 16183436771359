import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close, PriorityHigh } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Checkbox,
} from '@mui/material';

import ConfirmationDialog from 'commons/components/ConfirmationDialog/ConfirmationDialog.component';
import * as S from 'commons/components/Table/Table.styles';
import { OrderStatusEnum } from 'commons/enums';
import { PickingTask } from 'commons/types';
import {
  DefaultSortProps,
  RowTitleProps,
  SortOrder,
} from 'commons/types/listPageData.interface';
import { DataTableProps } from 'commons/types/orderItemData.interface';
import { TableActionRow, DataTable } from 'pages/dashboard/commons/components';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import {
  OpenBulkDialogConfig,
  OpenDialogConfig,
} from '../configs/open-dialog.config';
import {
  IOpenBulkDialogData,
  IOpenDialogData,
} from '../type/open-dialog-state.interface';

import BulkActionBtnMenu from './BulkActionBtnMenu.component';
import RowActionBtn from './RowActionBtn.component';

const rowTitles: RowTitleProps[] = [
  { title: 'PO Number' },
  { title: 'Order Type' },
  { title: 'Order Date', sortField: 'queued_at' },
  { title: 'Status' },
  { title: 'Picker/Packer' },
  { title: 'Priority', sortField: 'priority' },
];

const defaultSort: DefaultSortProps = {
  sortField: 'queued_at',
  sortOrder: SortOrder.DESC,
};

const Row: React.FC<{
  data: PickingTask;
  onCheckboxClick: (poNumber: string) => void;
  checked: boolean;
  selectedLocation?: number;
}> = ({ data, onCheckboxClick, checked, selectedLocation }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState<boolean>(false);
  const [openDialogData, setOpenDialogData] = useState<IOpenDialogData | null>(
    null,
  );
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

  // assign invoice and pharmacy data to picking_task inside packing_task
  // FIXME: to improve data from API, because extended picking task dont have invoice and pharmacy object
  const clonedPackingTask = structuredClone(data.packing_task);
  if (clonedPackingTask) {
    clonedPackingTask.picking_task.invoice = data.invoice;
    clonedPackingTask.picking_task.pharmacy = data.pharmacy;
  }

  const handleDetails = (): void => {
    setDetailsOpen(!detailsOpen);
  };

  const handleOpenDialog = (name: string) => {
    setOpenDialogData(OpenDialogConfig[name.toLocaleLowerCase()]);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogData(null);
    setOpen(false);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={handleDetails}
        open={detailsOpen}
      >
        <DialogTitle>Details</DialogTitle>
        <IconButton
          onClick={handleDetails}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <b>PO Number:</b> {data.po_number}
            <br />
            <b>Pharmacy ID:</b> {data.pharmacy_id}
            <br />
            <b>Order Date:</b>{' '}
            {new Date(data.queued_at).toLocaleDateString('id-ID')}
            <br />
            <b>Expected Dispatch Date:</b>{' '}
            {data.expected_dispatch_time
              ? new Date(data.expected_dispatch_time).toLocaleDateString(
                  'id-ID',
                )
              : null}
            <br />
            <b>Status:</b> {data.status}
            <br />
            <b>Flag Reason:</b> {data.reason}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <S.TableRow>
        <S.TableColumn>
          <Checkbox
            onClick={() => onCheckboxClick(data.po_number)}
            checked={checked}
          />
        </S.TableColumn>
        <S.TableColumn>
          {data.is_high_priority && <PriorityHigh />}
        </S.TableColumn>
        <S.TableColumn>{data.po_number}</S.TableColumn>
        <S.TableColumn style={{ textTransform: 'capitalize' }}>
          {data.po_type}
        </S.TableColumn>
        <S.TableColumn>
          {new Date(data.queued_at).toLocaleString('id-ID')}
        </S.TableColumn>
        <S.TableColumn
          style={{ textTransform: 'capitalize', paddingRight: '15px' }}
        >
          {data.status}
        </S.TableColumn>
        <S.TableColumn>
          <>
            <div>{`Picker: ${
              data.picker ? data.picker.split('@')[0] : '-'
            }`}</div>
            <div>
              {`Packer: ${
                data.packing_task?.packer
                  ? data.packing_task.packer.split('@')[0]
                  : '-'
              }`}
            </div>
          </>
        </S.TableColumn>
        <S.TableColumn>{data.priority}</S.TableColumn>
        <S.TableColumn style={{ textAlign: 'right' }}>
          <Button
            onClick={handleDetails}
            variant="outlined"
            style={{ marginRight: '4px' }}
          >
            Details
          </Button>
          {data.status === OrderStatusEnum.UPDATE_FAILED ? (
            <Button
              onClick={() => handleOpenDialog('retry')}
              variant="outlined"
              sx={{
                width: 87,
              }}
            >
              Retry
            </Button>
          ) : [OrderStatusEnum.QUEUE, OrderStatusEnum.PENDING].includes(
              data.status,
            ) ? (
            <Button
              onClick={
                data.is_high_priority
                  ? () => handleOpenDialog('unprioritize')
                  : () => handleOpenDialog('prioritize')
              }
              variant="outlined"
              disabled={data.picker !== null}
              sx={{
                width: 87,
              }}
            >
              {data.is_high_priority ? `Unprioritize` : `Prioritize`}
            </Button>
          ) : [OrderStatusEnum.PACKING, OrderStatusEnum.PACKED].includes(
              data.status,
            ) && clonedPackingTask ? (
            <RowActionBtn
              orderType={data.po_type}
              packingTask={clonedPackingTask}
              selectedLocation={selectedLocation}
            />
          ) : null}
        </S.TableColumn>
      </S.TableRow>
      <ConfirmationDialog
        title={openDialogData?.title || ''}
        content={openDialogData?.content(data.po_number) || ''}
        onClose={() => handleCloseDialog()}
        openModal={open}
        onConfirm={() =>
          openDialogData?.onConfirm(dispatch, data.po_number, selectedLocation)
        }
      />
    </>
  );
};

const OrderTable: React.FC<DataTableProps> = (params): React.ReactNode => {
  const { data = [] }: DataTableProps = params;

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [checkedOrders, setCheckedOrders] = useState<string[]>([]);

  const selectedOrders = data.filter((task) =>
    checkedOrders.includes(task.po_number),
  );

  const { selectedLocation } = useSelector(
    (state: RootReducerInterface) => state.dashboard,
  );

  const dispatch = useDispatch<AppDispatch>();
  const selectedPoNumbers = selectedOrders.map((order) => order.po_number);
  const [open, setOpen] = useState<boolean>(false);
  const [openDialogData, setOpenDialogData] =
    useState<IOpenBulkDialogData | null>(null);
  const handleCheckOrder = (poNumber: string): void => {
    if (checkedOrders.includes(poNumber)) {
      // remove poNumber from checkedOrders
      checkedOrders.splice(checkedOrders.indexOf(poNumber), 1);
      setCheckedOrders([...checkedOrders]);
    } else {
      // insert po number to checkedOrders
      setCheckedOrders([...checkedOrders, poNumber]);
    }
  };

  const handleCheckAll = (): void => {
    if (checkAll) {
      // remove all check list
      setCheckedOrders([]);
    } else {
      // add all po number to checkedOrders
      setCheckedOrders(data.map((pickTask) => pickTask.po_number));
    }
    setCheckAll(!checkAll);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (name: string) => {
    setOpenDialogData(OpenBulkDialogConfig[name]);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogData(null);
    setOpen(false);
  };

  return (
    <>
      <TableActionRow
        dataName="order"
        selectedData={selectedOrders}
        anchorEl={anchorEl}
        openMenu={openMenu}
        handleClickMenu={handleClickMenu}
      >
        <BulkActionBtnMenu
          selectedOrders={selectedOrders}
          handleCloseMenu={handleCloseMenu}
          handleOpen={handleOpenDialog}
        />
      </TableActionRow>
      <DataTable
        handleCheckAll={handleCheckAll}
        checkAll={checkAll}
        rowTitles={rowTitles}
        defaultSort={defaultSort}
      >
        <S.TableBody>
          {data.map((item) => (
            <Row
              data={item}
              key={item.po_number}
              onCheckboxClick={handleCheckOrder}
              checked={checkedOrders.includes(item.po_number)}
              selectedLocation={selectedLocation?.warehouse_id}
            />
          ))}
        </S.TableBody>
      </DataTable>
      <ConfirmationDialog
        title={openDialogData?.title || ''}
        content={openDialogData?.content(selectedPoNumbers) || ''}
        onClose={() => handleCloseDialog()}
        openModal={open}
        onConfirm={() =>
          openDialogData?.onConfirm(
            dispatch,
            selectedPoNumbers,
            selectedLocation?.warehouse_id,
          )
        }
      />
    </>
  );
};

export default OrderTable;
