/* eslint-disable react/no-unstable-nested-components */

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectUser } from '../../redux-stores/features/authSlice';

interface PrivateRouteProps {
  roles: string[];
  isHomePage?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ roles, isHomePage }) => {
  const user = useSelector(selectUser);

  const checkRoles = () => {
    if (user?.isAuthenticated) {
      if (isHomePage && user.roles.length > 1) {
        return true;
      }
      return roles.some((r) => {
        const resource = user.roles.includes(r);

        return resource;
      });
    }
    return false;
  };

  // eslint-disable-next-line react/no-unknown-property
  return checkRoles() ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
