import { CsvTemplateBuilder } from 'commons/utils/CSVTemplateBuilder';

const DeleteUserUploadTemplate = new CsvTemplateBuilder('delete-user');
DeleteUserUploadTemplate.setHeader(['email']);

DeleteUserUploadTemplate.addRowValues(['user1@tmpdepok.com']);
DeleteUserUploadTemplate.addRowValues(['user2@tmpsby.com']);
DeleteUserUploadTemplate.addRowValues(['user3@tmpdepok.com']);

export { DeleteUserUploadTemplate };
