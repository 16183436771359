import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import * as colors from 'commons/styles/colors';
import {
  PartialReplenishTaskSummary,
  ReplenishBinWithMissingQty,
  ReplenishTaskWithSummary,
} from 'commons/types';
import {
  getReplenishTasksAction,
  postCompleteReplenish,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import { ReplenishSkuList, PartialReplenishModal } from './components';
import { ReplenishSummaryStyle as S } from './ReplenishSummary.style';

export const ReplenishSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.replenishTask,
  );

  useEffect(() => {
    dispatch(getReplenishTasksAction({}));
  }, [dispatch]);

  const [completedSKU, setCompletedSKU] = useState<ReplenishTaskWithSummary[]>(
    [],
  );
  const [incompleteSKU, setIncompleteSKU] = useState<
    ReplenishTaskWithSummary[]
  >([]);

  useMemo(() => {
    const _completedSKU: ReplenishTaskWithSummary[] = [];
    const _incompleteSKU: ReplenishTaskWithSummary[] = [];

    Object.values(assignedTasks).forEach((task) => {
      // validate
      const batchQty: Record<string, { source: number; destination: number }> =
        {};
      const totalQty = { source: 0, destination: 0 };
      task.source_bins.forEach(({ inventory_number: batch, quantity }) => {
        if (!batchQty[batch]) {
          batchQty[batch] = { source: 0, destination: 0 };
        }
        batchQty[batch].source = (batchQty[batch].source || 0) + quantity;
        totalQty.source += quantity;
      });
      task.destination_bins.forEach(({ inventory_number: batch, quantity }) => {
        if (!batchQty[batch]) {
          batchQty[batch] = { source: 0, destination: 0 };
        }
        batchQty[batch].destination =
          (batchQty[batch].destination || 0) + quantity;
        totalQty.destination += quantity;
      });

      let isCompleted = true;
      if (totalQty.source !== totalQty.destination) {
        isCompleted = false;
      }

      const batchRemainsQty: { inventory_number: string; quantity: number }[] =
        [];
      const batchList = Object.keys(batchQty);
      for (let i = 0; i < batchList.length; i += 1) {
        const batch = batchList[i];
        if (batchQty[batch].source !== batchQty[batch].destination) {
          isCompleted = false;
          batchRemainsQty.push({
            inventory_number: batch,
            quantity: batchQty[batch].source - batchQty[batch].destination,
          });
        }
      }
      const totalBinQtyMissing: ReplenishBinWithMissingQty[] =
        task.destination_bin_requested
          .filter((bin) => bin.bin)
          .map((bin) => {
            const replenishedQty = task.destination_bins
              .filter((dest) => dest.bin === bin.bin)
              .reduce((total, dest) => {
                return total + dest.quantity;
              }, 0);
            return {
              ...bin,
              missing_qty: bin.requested_quantity - replenishedQty,
            };
          })
          .filter((bin) => bin.missing_qty > 0);

      if (isCompleted) {
        _completedSKU.push({ ...task, totalQty, batchQty, totalBinQtyMissing });
      } else {
        _incompleteSKU.push({
          ...task,
          totalQty,
          batchQty,
          batchRemainsQty,
          totalBinQtyMissing,
        });
      }
    });

    setCompletedSKU(_completedSKU);
    setIncompleteSKU(_incompleteSKU);
  }, [assignedTasks]);

  const [isCompleteButtonDisabled, setCompleteButtonDisabled] =
    useState<boolean>(true);
  useEffect(() => {
    if (incompleteSKU.length > 0) {
      setCompleteButtonDisabled(true);
    } else {
      setCompleteButtonDisabled(false);
    }
  }, [incompleteSKU.length]);

  const [openPartialReplenishModal, setOpenPartialReplenishModal] =
    useState<boolean>(false);
  const onRemoveModalClose = () => setOpenPartialReplenishModal(false);

  const [partialReplenishedSKU, setPartialReplenishedSKU] = useState<
    PartialReplenishTaskSummary[]
  >([]);
  useMemo(() => {
    const _partialReplenishedSKU: PartialReplenishTaskSummary[] = [];
    completedSKU.forEach((sku) => {
      if (sku.totalQty.destination < sku.quantity) {
        const partialSKU = {
          ...sku,
          totalQty: { ...sku.totalQty, requested: sku.quantity },
        };
        _partialReplenishedSKU.push(partialSKU);
      }
    });
    setPartialReplenishedSKU(_partialReplenishedSKU);
  }, [completedSKU]);

  const handleCompleteTask = async (): Promise<void> => {
    const { success, ...snackBarState } = await postCompleteReplenish();
    dispatch(snackbarSetData(snackBarState));
    if (success) {
      navigate('/replenish/assign');
    }
  };

  return (
    <>
      <Header
        title="Replenish Summary"
        prevPageHandler={() => {
          navigate('/replenish/list/destination');
        }}
      />

      {/* Body */}
      <S.ContentWrapper>
        {/* incomplete */}
        {incompleteSKU.length > 0 && (
          <ReplenishSkuList
            replenishTask={incompleteSKU}
            headerText="Incomplete Replenish"
            headerIcon={<ErrorOutlineOutlined />}
            headerColor={colors.PRIMARY_YELLOW}
            defaultExpanded
          />
        )}

        {/* completed */}
        {completedSKU.length > 0 && (
          <ReplenishSkuList
            replenishTask={completedSKU}
            headerText="Completed Replenish"
          />
        )}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton
              onClick={(): void => navigate('/replenish/list/destination')}
              data-testid="BtnReplenishSummaryGoBack"
            >
              GO BACK
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton
              disabled={isCompleteButtonDisabled}
              onClick={() =>
                partialReplenishedSKU.length > 0
                  ? setOpenPartialReplenishModal(true)
                  : handleCompleteTask()
              }
              data-testid="BtnReplenishSummaryComplete"
            >
              COMPLETE TASK
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>

      {/* Modal */}
      {openPartialReplenishModal && (
        <PartialReplenishModal
          partialReplenishedSKU={partialReplenishedSKU}
          onClose={onRemoveModalClose}
          openModal={openPartialReplenishModal}
          onConfirm={handleCompleteTask}
        />
      )}
    </>
  );
};
