import { createAsyncThunk } from '@reduxjs/toolkit';

import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { swiperxWmsApiLoginHistory } from 'services/apis/SwipeRxWmsApiLoginHistory';

export const createLoginHistory = createAsyncThunk(
  'loginHistory/createLoginHistory',
  async (stationNumber: number, { dispatch }) => {
    return defaultActionProcess(async () => {
      await swiperxWmsApiLoginHistory.createLoginHistory(stationNumber);
    }, dispatch);
  },
);

export const updateLogoutHistory = createAsyncThunk(
  'loginHistory/updateLogout',
  async (_, { dispatch }) => {
    return defaultActionProcess(async () => {
      await swiperxWmsApiLoginHistory.updateLogoutHistory();
    }, dispatch);
  },
);
