import React from 'react';

import {
  CheckCircleOutline,
  ExpandMore,
  KeyboardDoubleArrowRightOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  capitalize,
  Grid,
} from '@mui/material';

import * as colors from 'commons/styles/colors';
import { IStockTransferWithSummary } from 'commons/types';

import { StockTransferSummaryStyle as S } from '../StockTransferSummary.style';

import { StockTransferBinList } from './stock-transfer-bin-list.component';

interface StockTransferSkuListProps {
  stockTransfer: IStockTransferWithSummary[];
  headerText: string;
  headerIcon?: React.JSX.Element;
  headerColor?: string;
  defaultExpanded?: boolean;
}
export const StockTransferSkuList: React.FC<StockTransferSkuListProps> = ({
  stockTransfer,
  headerText,
  defaultExpanded,
  headerColor,
  headerIcon,
}) => {
  return (
    <>
      <S.HeaderWrapper>
        <S.Header color={headerColor || colors.PRIMARY_GREEN}>
          {headerIcon || <CheckCircleOutline />} {capitalize(headerText)}
        </S.Header>
      </S.HeaderWrapper>
      {stockTransfer.map((item, i) => {
        const qtyColor =
          item.totalQty.source === item.totalQty.destination
            ? colors.PRIMARY_BLACK
            : colors.PRIMARY_YELLOW;
        return (
          <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${i}-content`}
              id={`panel${i}-header`}
            >
              <Grid container style={{ fontWeight: 700 }}>
                <Grid item xs={5} data-testid={`TextSTSummarySKU-${i + 1}`}>
                  SKU {item.sku_code}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    textAlign: 'right',
                    verticalAlign: 'middle',
                    color: '#737373',
                  }}
                >
                  Stock Transfer:{' '}
                  <span style={{ color: qtyColor }}>
                    <span data-testid={`TextSTSummaryQtySource-${i + 1}`}>
                      {item.totalQty.source}
                    </span>{' '}
                    <KeyboardDoubleArrowRightOutlined
                      style={{
                        verticalAlign: 'text-bottom',
                      }}
                    />{' '}
                    <span data-testid={`TextSTSummaryQtyDestination-${i + 1}`}>
                      {item.totalQty.destination}
                    </span>
                  </span>
                </Grid>
                <Grid item xs={1} />
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid />
              <StockTransferBinList
                sources={item.source_bin}
                destinations={item.destination_bin}
                remainsQty={item.batchRemainsQty}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
