import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

class SwipeRxWmsApiLoginHistory extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.LOGIN_HISTORY);
    this.basePath = `/${this.apiResource}`;
  }

  async createLoginHistory(stationNumber: number): Promise<void> {
    try {
      await this.post(`${this.basePath}/create`, {
        station_number: stationNumber,
      });
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateLogoutHistory(): Promise<void> {
    try {
      await this.post(`${this.basePath}/update-logout`, {});
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swiperxWmsApiLoginHistory = new SwipeRxWmsApiLoginHistory();
