import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from 'react-webcam';

import { RadioButtonChecked } from '@mui/icons-material';
import { Button, Drawer, IconButton } from '@mui/material';

import { PRIMARY_WHITE } from 'commons/styles/colors';
import { uploadBasketPhoto } from 'redux-stores/actions/packingTask.action';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as S } from '../CheckBasket.styles';

interface BasketCameraProps {
  poNumber: string;
  basketNumber: number;
  openModal: boolean;
  onClose: () => void;
}

const BasketCamera: React.FC<BasketCameraProps> = ({
  poNumber,
  basketNumber,
  openModal,
  onClose,
}) => {
  const cameraRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const capture = useCallback(() => {
    if (cameraRef.current) {
      const photo = cameraRef.current.getScreenshot({
        width: 1920,
        height: 1080,
      });
      setImageSrc(photo as string);
    }
  }, [cameraRef]);

  const handleUploadPhoto = async (): Promise<void> => {
    if (imageSrc) {
      await dispatch(
        uploadBasketPhoto({
          poNumber,
          basket: basketNumber,
          imageSrc,
          onClose,
        }),
      );
    }
    setImageSrc(null);
  };

  const handleRetake = (): void => {
    setImageSrc(null);
  };

  const videoConstraints: MediaTrackConstraints = {
    width: {
      ideal: 1920,
      min: 1280,
    },
    height: {
      ideal: 1080,
      min: 720,
    },
    facingMode: 'environment',
  };

  return (
    <Drawer
      style={{ position: 'relative', width: '100%', height: '100%' }}
      anchor="bottom"
      open={openModal}
      onClose={onClose}
    >
      {imageSrc ? (
        <img src={imageSrc} alt="basket check" />
      ) : (
        <S.CameraWrapper>
          <Webcam
            audio={false}
            height="100%"
            width="100%"
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            ref={cameraRef}
            screenshotQuality={0.9}
          />
        </S.CameraWrapper>
      )}
      <S.CameraHeader container>
        <Button
          style={{ cursor: 'pointer', color: PRIMARY_WHITE, fontSize: '16px' }}
          onClick={onClose}
          data-testid="BtnCheckCancel"
        >
          Cancel
        </Button>
        {imageSrc && (
          <S.BoldText onClick={handleUploadPhoto} data-testid="BtnCheckDone">
            DONE
          </S.BoldText>
        )}
      </S.CameraHeader>
      <S.CameraFooter container>
        <IconButton onClick={capture}>
          <RadioButtonChecked
            style={{ color: PRIMARY_WHITE, width: '60px', height: '60px' }}
          />
        </IconButton>
        {imageSrc && (
          <Button
            style={{
              position: 'absolute',
              right: '32px',
              color: PRIMARY_WHITE,
            }}
            onClick={handleRetake}
            data-testid="BtnCheckRetake"
          >
            RETAKE
          </Button>
        )}
      </S.CameraFooter>
    </Drawer>
  );
};

export default BasketCamera;
