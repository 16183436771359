import { Grid, TextareaAutosize } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { LIGHT_GRAY, PRIMARY_GRAY } from 'commons/styles/colors';

class CompanyInfoPageStyle extends CommonComponentStyle {
  Title = styled.div`
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  `;

  Subtitle = styled.div`
    color: #000;
    font-size: 16px;
    font-weight: 700;
  `;

  InfoWrapper = styled(Grid)`
    gap: 16px;
  `;

  Label = styled.div`
    color: ${PRIMARY_GRAY};
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
  `;

  ApjSignatureWrapper = styled.div`
    width: 150px;
  `;

  TextArea = styled(TextareaAutosize)`
    width: 98% !important;
    font-family:
      Nunito Sans,
      sans-serif;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border-color: ${LIGHT_GRAY};
    resize: none !important;
  `;
}

export default new CompanyInfoPageStyle();
