import { CsvTemplateBuilder } from 'commons/utils/CSVTemplateBuilder';

const AddUserUploadTemplate = new CsvTemplateBuilder('add-user');
AddUserUploadTemplate.setHeader(['email', 'role', 'location']);

AddUserUploadTemplate.addRowValues(['user1@tmpdepok.com', 'admin', '1994']);
AddUserUploadTemplate.addRowValues(['user2@tmpsby.com', 'super_admin', '1993']);
AddUserUploadTemplate.addRowValues([
  'user3@tmpdepok.com',
  'picker;packer',
  '1994',
]);

export { AddUserUploadTemplate };
