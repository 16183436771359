/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import { OrderStatusEnum } from 'commons/enums';
import { OrderType, PriorityList } from 'commons/types';
import {
  BasketProductDetails,
  IPackingTask,
  KoliInfo,
} from 'commons/types/packingTask.interface';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import {
  ICheckBasketInfoPayloadDTO,
  ICheckBasketInfoResponseDTO,
  IGetPackTaskResponseDTO,
} from './interfaces';
import { IGetAllocationSuggestionResponseDTO } from './interfaces/get-allocation-suggestion-response-dto.interface';

const price = [10000, 20021, 30000];
const disc = [0, 0, 1, 2, 3];
const basketProductDetails: BasketProductDetails[] = [];
for (let i = 0; i < 8; i++) {
  basketProductDetails.push({
    sku_code: `ID10000-${i + 1}`,
    name: `Product 0${i} 50MG 1SACHET`,
    uom: 'DUS, 10 STRIP @ 10 KAPLET',
    quantity_ordered: 10,
    total_qty_picked: 10,
    check_passed: true,
    price: price[i % 3],
    discount: disc[i % 5],
    ppn: 0.11,
    is_modified: false,
    reason: '',
    pick_actual: [
      {
        basket: 5,
        warehouse_id: 1994,
        sku_code: `ID10000-${i + 1}`,
        bin: 'A1B1',
        bin_type: 'retail',
        quantity: 6,
        inventory_number: '202607-COL01A1 0',
        expiry_date: new Date('2027-10-30'),
        is_verified: false,
      },
      {
        basket: 5,
        warehouse_id: 1994,
        sku_code: `ID10000-${i + 1}`,
        bin: 'A1B1',
        bin_type: 'retail',
        quantity: 4,
        inventory_number: '202607-COL01A1 Z',
        expiry_date: new Date('2027-10-30'),
        is_verified: false,
      },
    ],
  });
}

const dummyPackTask: IPackingTask = {
  warehouse_id: 1994,
  po_number: 'PO1234567',
  packer: '1',
  logistic_name: 'Borzo',
  invoice_number: 'TMP-INV-2024/01/01 123456',
  invoice_id: 123456,
  koli: [{ weight: 1.1 }, { weight: 1.1 }, { weight: 1.1 }],
  allocation_staging: '',
  basket_infos: [
    {
      basket: 5,
      items: basketProductDetails,
      photo_url: [],
      is_scanned: true,
    },
    {
      basket: 8,
      items: basketProductDetails,
      photo_url: [],
      is_scanned: true,
    },
  ],
  picking_task: {
    status: OrderStatusEnum.PACKING,
    picker: '1',
    priority: PriorityList.P1,
    is_digital_signed: false,
    po_type: OrderType.PRECURSOR,
    pharmacy: {
      pt_id: 3,
      name: 'MClinica TestingMClinica TestingMClinica TestingMClinica TestingMClinica TestingMClinica',
      address: 'Jalan Jalan Beli Petasan 123',
      city: 'Jakarta',
      district: 'A',
      subdistrict: 'a',
      zipcode: '12345',
    },
    baskets: [5, 8],
  },
  createdAt: new Date(),
  updatedAt: new Date(),
};

class SwipeRxWmsPackingTask extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.PACKING_TASK);
    this.basePath = `/${this.apiResource}`;
  }

  // TODO: preserve this method until before release
  // to backup ui development keep going if api have refactor
  async getPackingTaskDummy(): Promise<IGetPackTaskResponseDTO> {
    try {
      return {
        data: dummyPackTask,
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPackingTaskByBasket(
    basket: number,
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.get(
        `${this.basePath}/get-packlist-by-basket/${basket}`,
      );

      if (!result.data) throw new Error('[FEError] Data not found');

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPackerOnProgressPackingTask(
    poNumber: string,
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.get(`${this.basePath}/get-on-progress-task`, {
        po_number: poNumber,
      });

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPackerOnProgressPackingTaskAllowOnUpdate(
    poNumber: string,
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.get(
        `${this.basePath}/get-on-progress-task-allow-on-update/`,
        {
          po_number: poNumber,
        },
      );
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async checkBasketInfo(
    payload: ICheckBasketInfoPayloadDTO,
  ): Promise<ICheckBasketInfoResponseDTO> {
    try {
      const result = await this.patch(
        `${this.basePath}/check-basket-info`,
        payload,
      );
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateQtyPicked(
    poNumber: string,
    payload: BasketProductDetails & { basket: number },
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.patch(
        `${this.basePath}/edit-qty/${poNumber}`,
        payload,
      );
      if (!result.data) throw new Error('[FEError] Update Qty Failed');
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async completeChecking(poNumber: string): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.post(`${this.basePath}/complete-checking`, {
        po_number: poNumber,
      });

      if (!result.data) throw new Error('[FEError] Complete Check failed');

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateKoliInfo(
    poNumber: string,
    koli: KoliInfo[],
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.patch(`${this.apiResource}/update/koli`, {
        koli,
        po_number: poNumber,
      });

      if (!result.data) throw new Error('[FEError] Data not found');
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllocationStagingSuggestion(
    warehouseId: number,
  ): Promise<IGetAllocationSuggestionResponseDTO> {
    try {
      const result = await this.get(
        `${this.apiResource}/recommendation/allocation_staging`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return {
        data: 'Pallete Borzo 3',
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateAllocationStaging(
    poNumber: string,
    allocationStaging: string | null,
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.patch(
        `${this.apiResource}/update/allocation_staging`,
        {
          allocation_staging: allocationStaging,
          po_number: poNumber,
        },
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async completePacking(poNumber: string): Promise<void> {
    try {
      await this.post(`${this.basePath}/complete-packing`, {
        po_number: poNumber,
      });
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getESPDocument(poNumber: string): Promise<string> {
    try {
      const result = await this.get(`${this.basePath}/esp/${poNumber}`);

      return result.data;
    } catch (error) {
      console.warn(error);
      return '';
    }
  }

  async resyncInvoice(poNumber: string): Promise<void> {
    try {
      await this.get(`${this.basePath}/resync-invoice/${poNumber}`);
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swipeRxWmsAPIPackingTask = new SwipeRxWmsPackingTask();
