import React from 'react';
import { Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import CompanyInfoPage from 'pages/dashboard/company-info/CompanyInfo';
import MasterBinPage from 'pages/dashboard/master-bin/MasterBin';
import OrderListPage from 'pages/dashboard/order-lists/OrderList';
import PharmacyUploadPage from 'pages/dashboard/pharmacy-upload/PharmacyUpload';
import ProductUploadPage from 'pages/dashboard/product-upload/ProductUpload';
import ReplenishListPage from 'pages/dashboard/replenish-list/ReplenishList';
import StockTransferListPage from 'pages/dashboard/stock-transfer-list/StockTransferList';
import SummariesPage from 'pages/dashboard/summaries/Summaries';
import UserListPage from 'pages/dashboard/user-list/UserList';
import UserUploadPage from 'pages/dashboard/user-upload/UserUpload';

export const useDashboardRouter = (): React.JSX.Element => (
  <>
    <Route
      path="/dashboard"
      element={
        <PrivateRoute
          roles={[
            UserRolesEnum.ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.PICKER_LEAD,
          ]}
        />
      }
    >
      <Route path="/dashboard" element={<SummariesPage />} />
      <Route path="/dashboard/order-item" element={<OrderListPage />} />
      <Route path="/dashboard/users" element={<UserListPage />} />
      <Route path="/dashboard/replenish-item" element={<ReplenishListPage />} />
      <Route
        path="/dashboard/stock-transfer-item"
        element={<StockTransferListPage />}
      />
      <Route path="/dashboard/product-upload" element={<ProductUploadPage />} />
    </Route>
    <Route
      path="/dashboard"
      element={<PrivateRoute roles={[UserRolesEnum.SUPER_ADMIN]} />}
    >
      <Route path="/dashboard/user-upload" element={<UserUploadPage />} />
      <Route
        path="/dashboard/pharmacy-upload"
        element={<PharmacyUploadPage />}
      />
    </Route>
    <Route path="/dashboard/company-info" element={<CompanyInfoPage />} />
    <Route path="/dashboard/bins" element={<MasterBinPage />} />
  </>
);
