import { createAsyncThunk } from '@reduxjs/toolkit';

import { PRIMARY_GREEN, PRIMARY_RED } from 'commons/styles/colors';
import { ICompanyInfo, OrderType } from 'commons/types';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { setCompanyInfo } from 'redux-stores/reducers/companyInfoReducer';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { swipeRxWmsAPICompany } from 'services/apis/SwipeRxWmsApiCompany';

export const getCompanyInfo = createAsyncThunk(
  'companyInfo/getCompanyInfo',
  async (params: { locationId?: number }, { dispatch }) => {
    return defaultActionProcess(async () => {
      const queries: Record<string, number> = {};
      if (params.locationId) queries.location = params.locationId;
      const response = await swipeRxWmsAPICompany.getUserCompanyInfo(queries);
      if (response && response.data) {
        dispatch(setCompanyInfo(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const updateCompanyInfo = createAsyncThunk(
  'companyInfo/updateCompanyInfo',
  async (payload: Partial<ICompanyInfo>, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPICompany.updateCompanyInfo(payload);
      if (response && response.data) {
        dispatch(setCompanyInfo(response.data));
        dispatch(
          snackbarSetData({
            open: true,
            message: 'Update Success',
            color: PRIMARY_GREEN,
          }),
        );
      }
      return response.data;
    }, dispatch);
  },
);

export const uploadApjSignature = createAsyncThunk(
  'companyInfo/updateApjSignature',
  async (
    params: {
      locationId?: number;
      apjType: OrderType;
      file: File;
    },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const formData = new FormData();
      formData.append(
        'file',
        new Blob(
          [await params.file.arrayBuffer()],

          { type: 'image/svg+xml' },
        ),
        `ttd-${params.apjType}.svg`,
      );
      formData.append('apjType', params.apjType);
      if (params.locationId) {
        formData.append('location', params.locationId.toString());
      }

      const response = await swipeRxWmsAPICompany.uploadApjSignature(
        params.apjType,
        formData,
      );
      if (response && response.data) {
        dispatch(setCompanyInfo(response.data));
        dispatch(
          snackbarSetData({
            open: true,
            message: 'Upload Sign Success',
            color: PRIMARY_GREEN,
          }),
        );
      }
      return response.data;
    }, dispatch);
  },
);
