import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowForwardIos } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import { userPackerGroup, userPickerGroup } from 'commons/types/userGroup';
import { CheckUserRoles } from 'commons/utils/checkUserRoles';

import UserInfoHeader from './components/UserInfoHeader.component';
import S from './HomePage.styles';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <UserInfoHeader />

      {CheckUserRoles([...userPickerGroup, ...userPackerGroup]) ? (
        <S.MenuOption
          onClick={() => navigate('/home/outbound')}
          data-testid="BtnModuleOutbound"
        >
          <Grid container>
            <Grid item xs={2}>
              <S.MenuIcon src="/assets/outbound-icon.svg" alt="outbound-icon" />
            </Grid>
            <Grid item xs={8}>
              <S.MenuText>Outbound</S.MenuText>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIos
                fontSize="small"
                style={{ marginTop: 14, textAlign: 'right' }}
              />
            </Grid>
          </Grid>
        </S.MenuOption>
      ) : null}

      {CheckUserRoles([UserRolesEnum.REPLENISH]) ? (
        <S.MenuOption
          onClick={() => navigate('/home/inbound')}
          data-testid="BtnModuleInbound"
        >
          <Grid container>
            <Grid item xs={2}>
              <S.MenuIcon src="/assets/inbound-icon.svg" alt="inbound-icon" />
            </Grid>
            <Grid item xs={8}>
              <S.MenuText>Inbound</S.MenuText>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIos
                fontSize="small"
                style={{ marginTop: 14, textAlign: 'right' }}
              />
            </Grid>
          </Grid>
        </S.MenuOption>
      ) : null}

      {/* Menu Options */}
      {/* Dashboard */}
      {CheckUserRoles([]) ? (
        <S.MenuOption
          onClick={(): void => navigate('/dashboard')}
          data-testid="BtnModuleDashboard"
        >
          <Grid container>
            <Grid item xs={2}>
              {/* Placeholder icon due to unavailability */}
              <S.MenuIcon
                src="/assets/checking-icon.svg"
                alt="dashboard-icon"
              />
            </Grid>
            <Grid item xs={8}>
              <S.MenuText>Dashboard</S.MenuText>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIos
                fontSize="small"
                style={{ marginTop: 18, textAlign: 'right' }}
              />
            </Grid>
          </Grid>
        </S.MenuOption>
      ) : null}
    </div>
  );
};

export default HomePage;
