import { CsvTemplateBuilder } from 'commons/utils/CSVTemplateBuilder';

const ProductDimensionBulkUploadTemplate = new CsvTemplateBuilder(
  'product-dimension-bulk-upload',
);
ProductDimensionBulkUploadTemplate.setHeader([
  'sku_code',
  'width',
  'length',
  'height',
  'weight',
]);

ProductDimensionBulkUploadTemplate.addRowValues([
  'ID000000-1',
  '15',
  '10',
  '5',
  '1',
]);
ProductDimensionBulkUploadTemplate.addRowValues([
  'ID000001-1',
  '10',
  '5',
  '5',
  '0.1',
]);
ProductDimensionBulkUploadTemplate.addRowValues([
  'ID000002-1',
  '5',
  '10',
  '15',
  '0.05',
]);

export { ProductDimensionBulkUploadTemplate };
