import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { updateLogoutHistory } from 'redux-stores/actions';
import { logout, selectUser } from 'redux-stores/features/authSlice';
import { AppDispatch } from 'redux-stores/store';

const Logout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLogout = (): void => {
    if (user.stationNumber) dispatch(updateLogoutHistory());
    dispatch(logout());
    setAnchorEl(null);
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <Navigate to="/" />;
};

export default Logout;
