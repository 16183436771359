import React, { useEffect, useState } from 'react';

import { AddCircleOutline, Close } from '@mui/icons-material';
import { Dialog, Grid, IconButton, TextField } from '@mui/material';

import { getBasketRecommendation } from 'redux-stores/actions';

import S from './AssignBasket.modal.styles';
import { IBaseAssignBasketModalProps } from './assignBasketModal.interface';

export const AssignBasketModal: React.FC<IBaseAssignBasketModalProps> = ({
  task,
  onClose,
  openModal,
  onConfirm,
  removeDisabled = false,
  disableExisting = false,
}) => {
  const emptyBasket = { id: null, errorText: '', disabled: false };
  const initBaskets =
    task && task.baskets.length > 0
      ? task.baskets.map((basket) => ({
          id: basket,
          errorText: '',
          disabled: disableExisting,
        }))
      : [emptyBasket];
  const [baskets, setBaskets] =
    useState<{ id: number | null; errorText: string; disabled: boolean }[]>(
      initBaskets,
    );
  const [basketRecommendation, setBasketRecommendation] = useState('');
  useEffect(() => {
    const updateBasketRecommendation = async (
      poNumber: string,
    ): Promise<void> => {
      const _basketReccommends = await getBasketRecommendation(poNumber);
      setBasketRecommendation(_basketReccommends);
    };
    if (task && task.po_number) {
      updateBasketRecommendation(task.po_number);
    }
  }, [task, task?.po_number]);

  // immediate return if null
  if (!task) {
    onClose();
    return <div />;
  }
  const currentBasketNumbers = [...task.baskets];

  // return error text if same basket number is assigned in the same SO
  const validateBasketNumber = (basketId: number): string => {
    const duplicateIds = baskets
      .map((basket) => basket.id)
      .filter((id) => id === basketId);

    if (duplicateIds.length > 0) return 'Basket already been assigned';
    return '';
  };
  const setBasketNumber = (index: number, basketId: string): void => {
    const basketIdNum = parseInt(basketId, 10);
    baskets[index].errorText = validateBasketNumber(basketIdNum);
    baskets[index].id = basketId ? basketIdNum : null;
    setBaskets([...baskets]);
  };

  const addBasket = (): void => setBaskets([...baskets, emptyBasket]);
  const removeBasket = (i: number): void => {
    if (baskets.length === 1) {
      setBaskets([emptyBasket]);
      return;
    }
    baskets.splice(i, 1);
    setBaskets([...baskets]);
  };

  const onConfirmProcess = (): void => {
    // check if basket change or not
    const _isBasketSame = (updatedBaskets: number[]): boolean => {
      if (updatedBaskets.length !== currentBasketNumbers.length) return false;

      return (
        updatedBaskets.filter(
          (basket) => !currentBasketNumbers.includes(basket),
        ).length === 0
      );
    };
    const updatedTask = { ...task };
    updatedTask.baskets = baskets
      .filter((basket) => basket.id !== null)
      .map((basket) => basket.id) as number[];

    if (!_isBasketSame(updatedTask.baskets)) {
      onConfirm(updatedTask);
    } else {
      onClose();
    }
  };

  const validateBasketFulfilled = (): boolean => {
    const basketWithoutNull = baskets.filter(
      (basket) => basket.id !== null && basket.errorText === '',
    );

    return basketWithoutNull.length === baskets.length;
  };

  const renderFieldInput = (): React.JSX.Element[] =>
    baskets.map((basket, idx) => (
      <S.FieldRowWrapper container>
        <Grid xs={2}>
          {!removeDisabled && (
            <IconButton
              onClick={(): void => removeBasket(idx)}
              data-testid={`BtnBasketRemove-${idx + 1}`}
            >
              <Close fontSize="small" />
            </IconButton>
          )}
        </Grid>
        <Grid xs={8}>
          <TextField
            variant="outlined"
            value={basket.id || ''}
            type="number"
            onChange={(event): void => setBasketNumber(idx, event.target.value)}
            error={basket.errorText !== ''}
            helperText={basket.errorText}
            style={{ width: '90%' }}
            disabled={basket.disabled}
            inputProps={{
              'data-testid': `InputBasketNumber-${idx + 1}`,
            }}
          />
        </Grid>
        <Grid xs={2}>
          <IconButton
            onClick={addBasket}
            data-testid={`BtnBasketAdd-${idx + 1}`}
          >
            <AddCircleOutline style={{ color: '#1733E8' }} />
          </IconButton>
        </Grid>
      </S.FieldRowWrapper>
    ));

  return (
    <Dialog onClose={onClose} open={openModal} fullWidth>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <S.TitleWrapper>
            <S.Title>ASSIGN BASKET TO:</S.Title>
            <S.SubTitle data-testid="TextBasketPO">{task.po_number}</S.SubTitle>
          </S.TitleWrapper>
        </Grid>
        {basketRecommendation && (
          <Grid item sm={6} xs={12}>
            <S.TitleWrapper>
              <S.Title>RECOMMENDATION:</S.Title>
              <S.SubTitle>{basketRecommendation}</S.SubTitle>
            </S.TitleWrapper>
          </Grid>
        )}
      </Grid>

      {renderFieldInput()}

      {/* Footer */}
      <S.FooterWrapper>
        <S.PrimaryButton
          onClick={onConfirmProcess}
          disabled={!validateBasketFulfilled()}
          data-testid="BtnBasketDone"
        >
          DONE
        </S.PrimaryButton>
      </S.FooterWrapper>
    </Dialog>
  );
};
