/* eslint-disable class-methods-use-this */
import { ICompanyInfo, OrderType } from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import { IGetCompanyResponseDTO } from './interfaces';

const dummyCompany: ICompanyInfo = {
  warehouse_id: 1994,
  name: 'PT. Teknologi Medika Pratama',
  address: `Komplek Pergudangan Kubik Logistics, Gudang E1 E2\nE7, Jl. Tugu Raya, RT.10/RW.10, Kel Tugu, Kec.\nCimanggis, Kota Depok, Jawa Barat Indonesia`,
  pbf_no: '12710004225910012',
  dak_no: '12710004225910014',
  cdob_no: '127100042259100120003',
  abbreviation: 'TMP',
  va_bca: '1203300115948407',
  va_mandiri: '8902232658877081',
  apj: {
    alkes: {
      name: 'Apt. Ade Mohamad Irsyad, S.Farm',
      sipa: '446/0278/SIPA/DPMPTSP/SIMPOK/VIII/2024',
      signature:
        'https://storage.googleapis.com/swiperx-wms-devx/wms/company-info/TTD_.png',
    },
    precursor: {
      name: 'Apt. Ade Mohamad Irsyad, S.Farm',
      sipa: '446/0278/SIPA/DPMPTSP/SIMPOK/VIII/2024',
      signature:
        'https://storage.googleapis.com/swiperx-wms-devx/wms/company-info/TTD_.png',
    },
    regular: {
      name: 'Apt. Ade Mohamad Irsyad, S.Farm',
      sipa: '446/0278/SIPA/DPMPTSP/SIMPOK/VIII/2024',
      signature:
        'https://storage.googleapis.com/swiperx-wms-devx/wms/company-info/TTD_.png',
    },
  },
};

class SwipeRxWmsCompany extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.COMPANY);
  }

  async getUserCompanyInfo(
    queries: Record<string, number>,
  ): Promise<IGetCompanyResponseDTO> {
    try {
      const result = await this.get(`${this.apiResource}`, queries);

      if (!result.data) throw new Error('[FEError] Data not found');
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateCompanyInfo(
    payload: Partial<ICompanyInfo>,
  ): Promise<IGetCompanyResponseDTO> {
    try {
      const result = await this.patch(`${this.apiResource}`, payload);

      if (!result.data) throw new Error('[FEError] Update Failed');
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async uploadApjSignature(apjType: OrderType, formData: FormData) {
    try {
      const result = await this.post(
        `${this.apiResource}/${apjType}/upload/apj-sign`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swipeRxWmsAPICompany = new SwipeRxWmsCompany();
