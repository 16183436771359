/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';

import { Box, Tabs, Tab } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { useUpdateQueryParams } from 'commons/components/SearchRelated/SearchWithCriteria/hooks';
import { FileImportType } from 'commons/enums';
import { getBulkUploadHistoryAction } from 'redux-stores/actions';

import { CustomTabPanel, FileImportTab, ListPage } from '../commons/components';

import { AddUserUploadTemplate, DeleteUserUploadTemplate } from './templates';

const UserUploadPage: React.FC = () => {
  const { updateQueryParams } = useUpdateQueryParams();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const updateUserUploadHistoryPageDebounce = useDebouncedCallback(
    (_searchParams, _location, _dispatch) => {
      _dispatch(
        getBulkUploadHistoryAction({
          searchParams: _searchParams,
          locationId: _location,
        }),
      );
    },
    200,
    { maxWait: 1000 },
  );

  useEffect(() => {
    updateQueryParams(
      { file_import_type: FileImportType.ADD_USER },
      { clearQueryParams: ['page'] },
    );
  }, []);

  return (
    <ListPage
      updateListSearchDebounce={updateUserUploadHistoryPageDebounce}
      pageSize="5"
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="Add User" />
          <Tab label="Delete User" />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <FileImportTab
          onDownloadTemplateClick={() => {
            AddUserUploadTemplate.createLink().click();
          }}
          downloadTemplateBtnText="Add User"
          uploadType={FileImportType.ADD_USER}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <FileImportTab
          onDownloadTemplateClick={() => {
            DeleteUserUploadTemplate.createLink().click();
          }}
          downloadTemplateBtnText="Delete User"
          uploadType={FileImportType.DELETE_USER}
        />
      </CustomTabPanel>
    </ListPage>
  );
};

export default UserUploadPage;
