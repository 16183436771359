import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckCircleOutline, ErrorOutlineOutlined } from '@mui/icons-material';
import { Card, CardContent, Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import * as colors from 'commons/styles/colors';
import { PickingSummary, PickingSummaryData } from 'commons/types';
import {
  getPickingTasksAction,
  getPickTaskBySKU,
  postComplete,
  validatePickingList,
} from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetData } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import InputLineDialog from './components/InputLineDialog';
import S from './PickSummary.style';

export const PickSummaryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const updatePickTaskBySKU = async (): Promise<void> => {
      dispatch(getPickTaskBySKU());
    };

    updatePickTaskBySKU();
  }, [dispatch]);

  const { tasksBySKUs, assignedTasks } = useSelector(
    (state: RootReducerInterface) => state.pickingTask,
  );
  const [isCompleteButtonDisabled, setCompleteButtonDisabled] = useState(false);
  const [completedSKU, setCompletedSKU] = useState<PickingSummary[]>([]);
  const [incompleteSKU, setIncompleteSKU] = useState<PickingSummary[]>([]);
  const [isOpenInputLineDialog, setIsOpenInputLineDialog] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(getPickingTasksAction());
  }, [dispatch]);

  const sortTasksByPriority = (tasks: PickingSummary[]) => {
    tasks.sort((a, b) => a.priority.localeCompare(b.priority));
    return tasks;
  };

  useMemo(() => {
    const _completedPick: PickingSummary[] = [];
    const _incompletePick: PickingSummary[] = [];
    assignedTasks.forEach((task) => {
      // get total qty picked to determine complete pick or not
      const totalQtyPicked = task.items.reduce(
        (total, item) =>
          total +
          item.pick_actual.reduce(
            (totalPicked, picked) => totalPicked + picked.quantity,
            0,
          ),
        0,
      );
      const isCompletePick = totalQtyPicked === task.total_qty;

      let prioIndex: number;
      // initiate summary
      // for complete pick
      if (isCompletePick) {
        prioIndex = _completedPick.findIndex(
          (item) => item.priority === task.priority,
        );
        // create new summary if there is no equivalent prio
        if (prioIndex < 0) {
          _completedPick.push({
            priority: task.priority,
            list: [
              {
                po_number: task.po_number,
                data: [
                  {
                    basket: task.baskets[0],
                    items: [],
                  },
                ],
              },
            ],
          });
          prioIndex = _completedPick.length - 1;
        }
        // push the list if there are already equivalent prio
        else {
          _completedPick[prioIndex].list.push({
            po_number: task.po_number,
            data: [
              {
                basket: task.baskets[0],
                items: [],
              },
            ],
          });
        }
      }
      // initiate summary
      // for incomplete pick
      else {
        prioIndex = _incompletePick.findIndex(
          (item) => item.priority === task.priority,
        );
        // create new summary if there is no equivalent prio
        if (prioIndex < 0) {
          _incompletePick.push({
            priority: task.priority,
            list: [
              {
                po_number: task.po_number,
                data: [
                  {
                    basket: task.baskets[0],
                    items: [],
                  },
                ],
              },
            ],
          });
          prioIndex = _incompletePick.length - 1;
        }
        // push the list if there are already equivalent prio
        else {
          _incompletePick[prioIndex].list.push({
            po_number: task.po_number,
            data: [
              {
                basket: task.baskets[0],
                items: [],
              },
            ],
          });
        }
      }
      task.items.forEach((item) => {
        // get total qty for certain sku
        const totalQtyPickedBySku = item.pick_actual.reduce(
          (total, pick) => total + pick.quantity,
          0,
        );

        // insert value with 0 quantity if not picked
        if (item.pick_actual.length === 0) {
          const poIndex = _incompletePick[prioIndex].list.findIndex(
            (po) => po.po_number === task.po_number,
          );
          const basketIndex = _incompletePick[prioIndex].list[
            poIndex
          ].data.findIndex((data) => data.basket === task.baskets[0]);
          _incompletePick[prioIndex].list[poIndex].data[basketIndex].items.push(
            {
              sku_code: item.sku_code,
              qty_ordered: item.quantity,
              total_qty_picked: 0,
              total_qty_picked_by_sku: 0,
            },
          );
        }

        item.pick_actual.forEach((picked) => {
          // handle complete pick
          if (isCompletePick) {
            const poIndex = _completedPick[prioIndex].list.findIndex(
              (po) => po.po_number === task.po_number,
            );
            // check if po already inserted or not (should've already inserted in initiation (LINE 68 and below))
            if (poIndex >= 0) {
              const basketIndex = _completedPick[prioIndex].list[
                poIndex
              ].data.findIndex((sku) => sku.basket === picked.basket);
              // check if basket already inserted or not
              if (basketIndex >= 0) {
                const skuIndex = _completedPick[prioIndex].list[poIndex].data[
                  basketIndex
                ].items.findIndex((sku) => sku.sku_code === item.sku_code);
                // if there are already item for corresponding sku, just increment the qty
                if (skuIndex >= 0) {
                  _completedPick[prioIndex].list[poIndex].data[
                    basketIndex
                  ].items[skuIndex].total_qty_picked += picked.quantity;
                }
                // if there is new sku (multiple sku in one basket),
                // push new item with qty from the pick actual
                else {
                  _completedPick[prioIndex].list[poIndex].data[
                    basketIndex
                  ].items.push({
                    sku_code: item.sku_code,
                    qty_ordered: item.quantity,
                    total_qty_picked: picked.quantity,
                    total_qty_picked_by_sku: totalQtyPickedBySku,
                  });
                }
              }
              // if there is new basket (multiple basket in one po)
              // insert new data
              else {
                _completedPick[prioIndex].list[poIndex].data.push({
                  basket: picked.basket,
                  items: [
                    {
                      sku_code: item.sku_code,
                      qty_ordered: item.quantity,
                      total_qty_picked: picked.quantity,
                      total_qty_picked_by_sku: totalQtyPickedBySku,
                    },
                  ],
                });
              }
            }
          }
          // handle incomplete pick
          else {
            const poIndex = _incompletePick[prioIndex].list.findIndex(
              (po) => po.po_number === task.po_number,
            );
            // check if po already inserted or not (should've already inserted in initiation (LINE 68 and below))
            if (poIndex >= 0) {
              const basketIndex = _incompletePick[prioIndex].list[
                poIndex
              ].data.findIndex((sku) => sku.basket === picked.basket);
              // check if basket already inserted or not
              if (basketIndex >= 0) {
                const skuIndex = _incompletePick[prioIndex].list[poIndex].data[
                  basketIndex
                ].items.findIndex((sku) => sku.sku_code === item.sku_code);
                // if there are already item for corresponding sku, just increment the qty
                if (skuIndex >= 0) {
                  _incompletePick[prioIndex].list[poIndex].data[
                    basketIndex
                  ].items[skuIndex].total_qty_picked += picked.quantity;
                }
                // if there is new sku (multiple sku in one basket),
                // push new item with qty from the pick actual
                else {
                  _incompletePick[prioIndex].list[poIndex].data[
                    basketIndex
                  ].items.push({
                    sku_code: item.sku_code,
                    qty_ordered: item.quantity,
                    total_qty_picked: picked.quantity,
                    total_qty_picked_by_sku: totalQtyPickedBySku,
                  });
                }
              }
              // if there is new basket (multiple basket in one po)
              // insert new data
              else {
                _incompletePick[prioIndex].list[poIndex].data.push({
                  basket: picked.basket,
                  items: [
                    {
                      sku_code: item.sku_code,
                      qty_ordered: item.quantity,
                      total_qty_picked: picked.quantity,
                      total_qty_picked_by_sku: totalQtyPickedBySku,
                    },
                  ],
                });
              }
            }
          }
        });
      });
    });
    // add the end, assign to state but sort the by the priority first
    setCompletedSKU(sortTasksByPriority(_completedPick));
    setIncompleteSKU(sortTasksByPriority(_incompletePick));
  }, [assignedTasks]);

  useEffect(() => {
    setCompleteButtonDisabled(
      validatePickingList(tasksBySKUs).validation === 'error',
    );
  }, [tasksBySKUs]);

  const renderBasketSummary = (
    task: PickingSummaryData[],
  ): React.JSX.Element[] =>
    task.map((item) => (
      <Grid xs={12}>
        <div>Basket No: {item.basket}</div>
        {item.items.map((data, idx) => (
          <Card variant="outlined" style={{ margin: '8px 0' }}>
            <CardContent style={{ padding: 8 }}>
              <Grid container>
                <Grid item xs={5}>
                  <S.SummaryName>SKU</S.SummaryName>
                </Grid>
                <Grid item xs={7}>
                  <S.SummaryValue data-testid={`TextSummarySKU-${idx + 1}`}>
                    {data.sku_code}
                  </S.SummaryValue>
                </Grid>
                <Grid item xs={4} sm={5}>
                  <S.SummaryName>Qty Picked</S.SummaryName>
                </Grid>
                <Grid item xs={8} sm={7}>
                  <S.SummaryValue>
                    <span>
                      {data.total_qty_picked !== data.total_qty_picked_by_sku &&
                        `(${data.total_qty_picked})`}
                    </span>
                    <span
                      style={{
                        color:
                          data.total_qty_picked_by_sku === data.qty_ordered
                            ? colors.PRIMARY_GREEN
                            : data.total_qty_picked === 0
                            ? colors.PRIMARY_GRAY
                            : colors.PRIMARY_RED,
                      }}
                      data-testid={`TextSummaryQTYPicked-${idx + 1}`}
                    >
                      {data.total_qty_picked_by_sku}
                    </span>
                    <span data-testid={`TextSummaryQTYOrder-${idx + 1}`}>
                      /{data.qty_ordered}
                    </span>
                  </S.SummaryValue>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Grid>
    ));

  const handleCloseInputLineDialog = () => {
    setIsOpenInputLineDialog(false);
  };

  const handleOpenInputLineDialog = () => {
    setIsOpenInputLineDialog(true);
  };

  const goToAssignPickTask = (): void => {
    navigate('/pick-task/assign');
  };

  const completeTask = async (lineNumber: number): Promise<void> => {
    const validationStatus = validatePickingList(tasksBySKUs);
    if (validationStatus.validation === 'error') {
      dispatch(
        snackbarSetData({
          open: true,
          message: validationStatus.message,
          color: colors.PRIMARY_RED,
        }),
      );
      return;
    }
    // TODO warn if validation === warning

    dispatch(postComplete({ lineNumber, next: goToAssignPickTask }));
  };

  const renderSKUSummaryItem = (
    pickSummary: PickingSummary,
  ): React.JSX.Element => {
    return (
      <>
        <S.PriorityText>Priority: {pickSummary.priority}</S.PriorityText>
        {pickSummary.list.map((task, idx) => (
          <S.SKUSummaryWrapper>
            <Grid
              container
              style={{
                fontWeight: 700,
                marginBottom: '16px',
              }}
            >
              <Grid item xs={6} data-testid={`TextSummaryPO-${idx + 1}`}>
                {task.po_number}
              </Grid>
            </Grid>
            <Grid container style={{ gap: '16px' }}>
              {task.data && renderBasketSummary(task.data)}
            </Grid>
          </S.SKUSummaryWrapper>
        ))}
      </>
    );
  };

  const renderSKUSummary = (
    pickSummary: PickingSummary[],
  ): React.JSX.Element[] => {
    const element: React.JSX.Element[] = [];

    pickSummary.forEach((task) => {
      element.push(renderSKUSummaryItem(task));
    });
    return element;
  };

  return (
    <>
      <Header
        title="Pick Summary"
        prevPageHandler={() => {
          navigate('/pick-task/list');
        }}
      />

      {/* Content Section */}
      <S.ContentWrapper>
        {Object.keys(incompleteSKU).length > 0 && (
          <>
            <S.HeaderWrapper>
              <S.Header color={colors.PRIMARY_YELLOW}>
                <ErrorOutlineOutlined />
                Incomplete Order
              </S.Header>
              <S.SubHeader>Below is the summary of the task.</S.SubHeader>
            </S.HeaderWrapper>
            {renderSKUSummary(incompleteSKU)}
          </>
        )}

        {Object.keys(completedSKU).length > 0 && (
          <>
            <S.HeaderWrapper>
              <S.Header color={colors.PRIMARY_GREEN}>
                <CheckCircleOutline />
                Picking Completed
              </S.Header>
              <S.SubHeader>Below is the summary of the task.</S.SubHeader>
            </S.HeaderWrapper>
            {renderSKUSummary(completedSKU)}
          </>
        )}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>

      {/* Footer Section */}
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton
              onClick={(): void => navigate('/pick-task/list')}
              data-testid="BtnSummaryGoBack"
            >
              GO BACK
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton
              disabled={isCompleteButtonDisabled}
              onClick={handleOpenInputLineDialog}
              data-testid="BtnSummaryComplete"
            >
              COMPLETE TASK
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
      <InputLineDialog
        onClose={handleCloseInputLineDialog}
        openModal={isOpenInputLineDialog}
        onConfirm={completeTask}
      />
    </>
  );
};
