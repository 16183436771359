/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Dialog, TextField } from '@mui/material';

import * as S from './InputLineDialog.style';

interface IInputLineDialogProps {
  onClose: () => void;
  onConfirm: (lineNumber: number) => Promise<void>;
  openModal: boolean;
  callOnCloseOnHandleConfirm?: boolean;
}

const InputLineDialog: React.FC<IInputLineDialogProps> = ({
  onClose,
  openModal,
  onConfirm,
  callOnCloseOnHandleConfirm = true,
}) => {
  const [lineNumber, setLineNumber] = useState<number>(0);

  const handleConfirm = () => {
    onConfirm(lineNumber);
    if (callOnCloseOnHandleConfirm) onClose();
  };

  const handleOnChange = (value: string) => {
    if (value === '') {
      setLineNumber(0);
      return;
    }
    const basketValue =
      typeof value === 'string' ? parseInt(value, 10) : (value as number);
    setLineNumber(basketValue);
  };

  return (
    <Dialog
      onClose={onClose}
      open={openModal}
      style={{ whiteSpace: 'pre-line' }}
    >
      <S.TitleWrapper>
        <S.Title>Scan/Input line</S.Title>
      </S.TitleWrapper>

      <S.InputWrapper>
        <TextField
          value={lineNumber !== 0 ? lineNumber : ''}
          type="number"
          onChange={(event) => handleOnChange(event.target.value)}
        />
      </S.InputWrapper>

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>NO</S.CancelButton>
        <S.OKButton onClick={handleConfirm}>YES</S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};

export default InputLineDialog;
