import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  Grid,
  MenuItem,
  Select,
  TextField,
  capitalize,
} from '@mui/material';

import { BinType } from 'commons/enums';
import { ProductDetail } from 'commons/types';
import { postCreateBin } from 'redux-stores/actions';
import { AppDispatch } from 'redux-stores/store';

import * as S from '../styles/CreateBinDialog.styles';
import { ICreateBinModalProps } from '../types';

export const CreateBinDialog: React.FC<ICreateBinModalProps> = ({
  onClose,
  openModal,
  location,
}) => {
  const [bin, setBin] = useState<Partial<ProductDetail>>({
    bin: '',
    bin_type: '',
    sku_code: '',
  });

  const dispatch = useDispatch<AppDispatch>();

  const onConfirm = async (): Promise<void> => {
    if (bin && bin.bin && bin.bin_type && bin.sku_code) {
      dispatch(
        postCreateBin({
          bin: { bin: bin.bin, bin_type: bin.bin_type, sku_code: bin.sku_code },
          location,
        }),
      );
    }
  };

  const onFieldChange = (
    _type: keyof ProductDetail,
    value: string | null,
  ): void => {
    let newBin = structuredClone(bin);
    const newValue = typeof value === 'string' ? value.trim() : value;
    newBin = { ...newBin, [_type]: newValue };
    setBin(newBin);
  };

  return (
    <Dialog
      onClose={onClose}
      open={openModal}
      style={{ whiteSpace: 'pre-line' }}
    >
      <S.TitleWrapper>
        <S.Title>Create New Bin</S.Title>
      </S.TitleWrapper>
      <Grid container style={{ marginTop: '12px', width: '450px' }}>
        <S.FieldRowWrapper container item xs={12}>
          <Grid item xs={4}>
            <S.Text>Bin Number</S.Text>
          </Grid>
          <S.InputWrapper>
            <TextField
              placeholder="Bin Number"
              variant="outlined"
              value={bin.bin || ''}
              onChange={(event): void =>
                onFieldChange('bin', event.target.value)
              }
              fullWidth
            />
          </S.InputWrapper>
        </S.FieldRowWrapper>
        <S.FieldRowWrapper container item xs={12}>
          <Grid item xs={4}>
            <S.Text>SKU Code</S.Text>
          </Grid>
          <S.InputWrapper>
            <TextField
              placeholder="SKU Code"
              variant="outlined"
              value={bin.sku_code || ''}
              onChange={(event): void =>
                onFieldChange('sku_code', event.target.value)
              }
              fullWidth
            />
          </S.InputWrapper>
        </S.FieldRowWrapper>
        <S.FieldRowWrapper container item xs={12}>
          <Grid item xs={4}>
            <S.Text>Bin Type</S.Text>
          </Grid>
          <S.InputWrapper>
            <Select
              value={bin.bin_type || ''}
              onChange={(event): void =>
                onFieldChange('bin_type', event.target.value)
              }
              fullWidth
            >
              {Object.values(BinType)
                .filter(
                  (_binType) =>
                    ![BinType.PALLET, BinType.NONE].includes(_binType),
                )
                .map((_binType) => (
                  <MenuItem value={_binType}>
                    {capitalize(_binType.replace('_', ' '))}
                  </MenuItem>
                ))}
            </Select>
          </S.InputWrapper>
        </S.FieldRowWrapper>
      </Grid>
      <S.FooterWrapper>
        <S.OKButton
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Submit
        </S.OKButton>
        <S.CancelButton onClick={onClose}>Cancel</S.CancelButton>
      </S.FooterWrapper>
    </Dialog>
  );
};
